const module ={
    namespaced : true,
    state:{
       loanDataAttributes:{},
       accountWiseDatas:{},
       transactionWiseDatas:{}, 
    },
    getters:{
        loanDataAttributes(state){
            return state.loanDataAttributes;
        },
        accountWiseDatas(state){
            return state.accountWiseDatas;
        },
        transactionWiseDatas(state){
            return state.transactionWiseDatas;
        }
    },
    mutations:{
        setLoanDataAttributes(state,payload){
            state.loanDataAttributes = payload;
        },
        setAccountWiseDatas(state,payload){
            state.accountWiseDatas = payload;
        },
        setTransactionWiseDatas(state,payload){
            state.transactionWiseDatas = payload;
        }
    }
}
export default module;