const module ={
    namespaced : true,
    state:{
       countDatas:{},
    },
    getters:{
       countDatas(state){
           return state.countDatas;
       }
    },
    mutations:{
        setCountDatas(state,payload){
            state.countDatas=payload;
        }
    }
}
export default module;