const module ={
    namespaced : true,
    state:{
       featureLists:{},
    },
    getters:{
       featureLists(state){
           return state.featureLists;
       }
    },
    mutations:{
        setFeatureLists(state,payload){
            state.featureLists = payload;
        }
    },
}
export default module;