const module ={
    namespaced : true,
    state:{
        loanLists:{},
        loanElements:{},
    },
    getters:{
       loanLists(state){
           return state.loanLists;
       },
       loanElements(state){
           return state.loanElements;
       }
    },
    mutations:{
        setLoanLists(state,payload){
            state.loanLists = payload;
        },
        setLoanElements(state,payload){
            state.loanElements = payload;
        }
    }
}
export default module;