const module ={
    namespaced : true,
    state:{
        salesId:'',
        customerDatas:{},
        customerAllLists:{},
        salesDatas:{},
        receiptDatas:{},
        payOutDatas:{},
        ledgerDatas:{},
        customerLists:{},
        receiptParams:{},
        ledgerParams:{},
        payOutParams:{},

    },
    getters:{
       salesId(state){
           return state.salesId;
       },
       salesDatas(state){
            return state.salesDatas;
       },
       receiptDatas(state){
           return state.receiptDatas;
       },
       payOutDatas(state){
           return state.payOutDatas;
       },
       ledgerDatas(state){
           return state.ledgerDatas;
       },
       customerDatas(state){
           return state.customerDatas;
       },
       customerLists(state){
           return state.customerLists;
       },
       customerAllLists(state){
        return state.customerAllLists;
       },
       receiptParams(state){
           return state.receiptParams;
       },
       ledgerParams(state){
           return state.ledgerParams;
       },
       payOutParams(state){
           return state.payOutParams;
       }
    },
    mutations:{
        setSalesId(state,payload){
            state.salesId = payload;
        },
        setSalesDatas(state,payload){
            state.salesDatas = payload;
        },
        setLedgerDatas(state,payload){
            state.ledgerDatas = payload;
        },
        setPayOutDatas(state,payload){
            state.payOutDatas = payload;
        },
        setReceiptDatas(state,payload){
            state.receiptDatas = payload;
        },
        setCustomerDatas(state,payload){
            state.customerDatas = payload;
        },
        setCustomerLists(state,payload){
            state.customerLists = payload;
        },
        setCustomerAllLists(state,payload){
            state.customerAllLists = payload;
        },
        setReceiptParams(state,payload){
            state.receiptParams = payload;
        },
        setLedgerParams(state,payload){
            state.ledgerParams = payload;
        },
        setPayOutParams(state,payload){
            state.payOutParams = payload;
        }
    }
}
export default module;