const module ={
    namespaced : true,
    state:{

        journalData:{},
              
    },
    getters:{
        journalData(state){
            return state.journalData;
        },
        
    },
    mutations:{
       setJournalData(state,payload){
            state.journalData = payload;
        }
    }
}
export default module;