const module ={
    namespaced: true,
    state:{
        dataSets:{
            branches:[],
            items:[]
        },
        componentClicked:false,
        item:{
            id:'',
            name:'',
            group:'',
            unit_type:''
        },
        itemDetails:[]
    },
    getters:{
      dataSets(state){
          return state.dataSets;
      },
      item(state){
        return state.item;
      },
      itemDetails(state){
          return state.itemDetails;
      },
      componentClicked(state){
          return state.componentClicked;
      }
     
    },
    mutations:{
        setDataSetsBranches(state,payload){
            state.dataSets.branches = payload;
        },
        setDataSetsItems(state,payload){
            state.dataSets.items = payload;
        },
       setItem(state,payload){
           state.item = payload;
       },
       setItemDetails(state,payload){
           state.itemDetails = payload;
       },
       setComponentClicked(state,payload){
           state.componentClicked = payload;
       }
    },
    actions:{
    }
}
export default module;