const module ={
    namespaced : true,
    state:{
        itemGroupUnique :true,
        itemGroupLists:{},
        itemGroupElements:{},
    },
    getters:{
        itemGroupUnique(state){
            return state.itemGroupId;
        },
        itemGroupLists(state){
            return state.itemGroupLists;
        },
        itemGroupElements(state){
            return state.itemGroupElements;
        }
    },
    mutations:{
        setItemGroupLists(state,payload){
            state.itemGroupLists = payload;
        },
        setItemGroupElements(state,payload){
            state.itemGroupElements = payload;
        },
        setItemGroupUnique(state,payload){
            state.itemGroupUnique = payload;
        }
    },
}
export default module;