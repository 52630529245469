<template>
  <div>
    <component
      :class="toggleSidebar ? 'toggle-sidebar' : ''"
      :is="$route.meta.key"
    />
    <div class="notification">
      <b-alert
        v-model="messageState"
        class="position-fixed m-0 rounded-0"
        style="
          z-index: 2000;
          width: 22rem;
          position: fixed;
          top: 0.5rem;
          right: 0.5rem;
          z-index: 1030;
        "
        :variant="eventMessage.indexOf('success') >= 0 ? 'success' : 'warning'"
      >
        <div class="d-flex flex-grow-1 align-items-baseline">
          <b-img
            blank
            blank-color="#ff5555"
            class="mr-2"
            width="12"
            height="12"
          ></b-img>
          <strong class="mr-auto">Alert!</strong>
          <small class="text-muted">42 seconds ago</small>
        </div>
        <small>{{ eventMessage }}</small>
      </b-alert>
    </div>
  </div>
</template>



<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import Backend from "./layouts/dashboard";
import Website from "./layouts/website";
import Login from "./layouts/login";
import YearTransfer from "./layouts/yearTransfer"
export default {
  components: { Backend, Website, Login, YearTransfer },
  computed: {
    ...mapGetters(["toggleSidebar", "messageState", "eventMessage"]),
  },
  watch: {
    eventMessage(value) {
      if (value) {
        setTimeout(() => {
          this.$store.dispatch("disableMessage");
          this.$store.commit("setEventMessage", "");
        }, 7000);
      }
      if (value.indexOf("success") >= 0) {
        this.$store.dispatch("modalClose");
      }
    },
  },
};
</script>
<style>
  /* Sweeralert changes */
  .swal2-popup {
    background: #c1d6ec !important;
  }
  .swal2-styled {
    padding: 0.25rem 0.5rem !important;
    min-width: 130px;
  }
  .swal2-styled.swal2-confirm {
    background: #1b52bf !important;
    background-color: #1B52BF !important;
    font-size: 0.875rem !important;
  }
  .swal2-styled.swal2-cancel {
    background: #dc3545 !important;
    font-size: 0.875rem !important;
  }
  /* .swal2-header {
    padding: 0 !important;
  }
  .swal2-content {
    padding: 0 !important;
  } */
  .swal2-title {
    font-size: 1.5em !important;
  }
  .swal2-html-container {
    border: none;
    padding: 15px 10px 5px 10px;
    box-shadow: 0 0px 1px 1px rgb(62 64 70 / 32%);
  }
  .swal2-html-container label{
    position: absolute;
    margin-top: -25px;
    background-color: #c1d6ec;
    margin-left: -3px;
    font-size: 0.75rem;
    margin-bottom: 0 !important;
  }
  .swal2-input {
    background: #f4f7fa !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    height: 3rem !important;
    font-size: 0.75rem !important;
  }
  .refund-check {
    float: left;
    margin: 15px -10px 0;
    font-size: 0.875rem;
  }
  /* Pagination Design */
  .pagination{
    border: none;
    margin-top: 10px;
    background-color: #c1d6ec;
    float:  right;
  }
  .pagination li{
    padding: 6px;
    border: 1px solid #f4f7fa;
  }
  .pagination .active{
    color: #f4f7fa  !important;
    background: #1b52bf !important;
  }
  .pagination .disabled{
    color: gray;
  }
  .print-detail-only {
    display: none;
  }
  .print-customer-detail {
    display: none;
  }
  .print-display-only-block {
    display: none;
  }
  .print-sm-hr {
      display: none;
    }
  @media print {
    @page
    {
        size: auto;
        margin: 20px 10px;
    }
    .no-print, .no-print *
    {
        display: none !important;
    }
    .sidebar {
      display: none;
    }
    .content {
      margin-left: 0;
    }
    .content-page {
      background: none !important;
    }
    .modal {
      background-color: #fff !important;
      background-repeat: none;
      overflow: visible;
    }
    .modal-dialog {
        max-width: 100% !important;
        margin: 0 auto;
    }
    .modal-dialog-centered {
        display: block;
    }
    .print-detail-only {
      display: flex;
      justify-content: center;
    }
    .content-body {
      padding-top: 4.5rem;
    }
    .print-display-only-block {
      display: block;
    }
    .pagination{
      display: none;
    }
    .content-body {
      overflow: visible;
    }
    .bill-container {
      overflow: visible;
      margin: 0 20px;
    }
    .profile-body-header, .table-search-head {
      display: none;
    }
    .p {
        color: #000 !important;
    }
    hr {
        border-top: 1px solid rgb(0 0 0 / 60%);
    }
    .table {
        color: #000 !important;
    }
    .table th {
        border-top: 1px solid rgb(0 0 0 / 60%) !important;
        border-bottom: 1px solid rgb(0 0 0 / 60%) !important;
    }
    .table td {
        border-bottom: 1px solid rgb(0 0 0 / 60%);
    }
    .footer_detail {
        border: 1px solid rgb(0 0 0 / 60%) !important;
    }
    .table-bordered th, .table-bordered td {
        border: 1px solid rgb(0 0 0 / 60%) !important;
    }
    .content-label {
        color: rgba(0, 0, 0, 0.774);
    }
    .table2 thead tr, .table thead tr th {
      color: #000;
      font-weight: 500 !important;
    }
    .content-label, ul, li, td, p {
      color: #000000;
    }
    .profile-body {
      width: 100%;
      border: none;
      box-shadow: none;
    }
    .print-p-0 {
      padding: 0 !important;
    }
    .print-customer-detail {
      display: block;
      background: #fff;
      padding-bottom: 20px;
    }
    .cancel-watermark {
      color: #b2b2b2b8;
    }
    .print-mb-3 {
      margin-bottom: 1.5rem;
    }
    
    @media (max-width: 450px) {
        .p {
          font-size: 0.825rem !important;
        }
        .font-print-xxs {
            font-size: 1rem !important;
        }
        .table th, .table3 th {
            font-size: 0.825rem !important;
        }
        .table td, .table3 td {
            font-size: 0.825rem !important;
        }
        .no-print-sm {
            display: none !important;
        }
        .font-xxxl {
            font-size: 1.5rem !important;
        }
        .print-sm-hr {
          display: block !important;
        }
        .print-hr-margin {
          margin: 5px !important;
        }
        .print-sm-mg {
            margin-left: 25%;
        }
        .print-detail-only {
          display: none !important;
        }
    }
  }
</style>
