const module = {
    namespaced: true,
    state: {
        itemSearchLoading: false,
        transferItem: {},
        itemGroups: [],
        dataSets: [],
        itemsByBranch: {
            items: [],
            branches: [],
        },
        branchId: '',
        slipSettings: [],
        verifyId: '',
        verifying: false,
    },
    getters: {
        itemGroups(state) {
            return state.itemGroups;
        },
        transferItem(state) {
            return state.transferItem;
        },
        dataSets(state) {
            return state.dataSets;
        },
        itemSearchLoading(state) {
            return state.itemSearchLoading;
        },
        itemsByBranch(state) {
            return state.itemsByBranch;
        },
        branchId(state) {
            return state.branchId;
        },
        slipSettings(state) {
            return state.slipSettings;
        },
        verifyId(state) {
            return state.verifyId;
        },
        verifying(state) {
            return state.verifying;
        }

    },
    mutations: {
        setItemGroups(state, payload) {
            state.itemGroups = payload;
        },
        setTransferItem(state) {
            return state.transferItem;
        },
        setDataSets(state, payload) {
            state.dataSets = payload;
        },
        setItemSearchLoading(state, boolean) {
            state.itemSearchLoading = boolean;
        },
        setItemsByBranch(state, payload) {
            state.itemsByBranch = payload;
        },
        setBranchId(state, id) {
            state.branchId = id;
        },
        setItemsByBranchItems(state) {
            state.itemsByBranch.items = [];
        },
        setSlipSettings(state, payload) {
            state.slipSettings = payload;
        },
        setVerifyId(state, payload) {
            state.verifyId = payload;
        },
        setVerifying(state, boolean) {
            state.verifying = boolean;
        }
    },
    actions: {
    }
}
export default module;