<template>
  <div>
    <div
      class="smart-way-section py-5"
      :style="{'background-image':'url(assets/icon/footer-theam.png)','background-size': '100%','background-repeat': 'no-repeat', 'background-position': 'center'}"
    >
      <div class="container section-title">
        <h3 class="title text-center text-capitalize">
          <span>Choose A Smart Way For</span> Your Business
        </h3>
        <p class="text-center text-capitalize">You Will Get The Best Service, Be Assure!</p>
        <div class="row">
          <div class="col-md-12 text-center pt-1" v-if="$route.path=='/contact-us'">
            <freeTrial />
            <getStart />
          </div>
          <div class="col-md-12 text-center pt-1" v-else>
            <getStart />
            <router-link
              to="/contact-us"
              :style="{ cursor: 'pointer' }"
              class="btn btn-outline-primary border-radius-0 ml-3"
              tag="a"
            >CONTACT US</router-link>
          </div>
        </div>
      </div>
    </div>
    <footer id="team" class="footer-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-8 pl-0">
            <div class="footer-about mt-4 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
              <a class="logo" href="javascript:;">
                <img src="assets/img/softechparklogo-1_2x_720.png" />
              </a>
              <p class="text mt-3">
                Lorem ipsum dolor sit amet consetetur sadipscing elitr,
                sederfs diam nonumy eirmod tempor invidunt ut labore et dolore
                magna aliquyam.
              </p>
            </div>
          </div>
          <div class="col-lg-7 col-md-7 col-sm-7">
            <div class="footer-link d-flex mt-50 justify-content-md-between">
              <div class="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
                <div class="footer-title">
                  <h5 class="title">General</h5>
                </div>
                <ul class="link py-1">
                  <li>
                    <a href="javascript:;">About Us</a>
                  </li>
                  <li>
                    <a href="javascript:;">About Product</a>
                  </li>
                  <li>
                    <a href="javascript:;">Security</a>
                  </li>
                  <li>
                    <a href="javascript:;">Offers & Discounts</a>
                  </li>
                </ul>
              </div>
              <div class="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
                <div class="footer-title">
                  <h5 class="title">Product</h5>
                </div>
                <ul class="link py-1">
                  <li>
                    <a href="javascript:;">Feature</a>
                  </li>
                  <li>
                    <a href="javascript:;">Plan & Pricing</a>
                  </li>
                  <li>
                    <a href="javascript:;">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="javascript:;">Terms & Condition</a>
                  </li>
                </ul>
              </div>
              <div class="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
                <div class="footer-title">
                  <h5 class="title">Help</h5>
                </div>
                <ul class="link py-1">
                  <li>
                    <a href="javascript:;">User's Guide</a>
                  </li>
                  <li>
                    <a href="javascript:;">FAQ's</a>
                  </li>
                  <li>
                    <a href="javascript:;">Video Tutorials</a>
                  </li>
                  <li>
                    <a href="javascript:;">Contact Us</a>
                  </li>
                  <li>
                    <a href="javascript:;">Payment Method</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-5 col-sm-5 pr-0">
            <div class="link-wrapper mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
              <div class="footer-title">
                <h5 class="title text-right">Application</h5>
              </div>
              <ul class="link py-1 text-right">
                <li>
                  <a href="javascript:;">Become a partner</a>
                </li>
                <li>
                  <a href="javascript:;">Become an Agent</a>
                </li>
                <li>
                  <a href="javascript:;">Become a Affiliate</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row mt-1" style="border-top: 1px solid;">
          <div class="col-md-6 pl-0 pt-3">
            <p>All Right Reserved - Developed By <a href="https://www.softechpark.com">Softechpark</a></p>
          </div>
          <div class="col-md-6 text-right">
            <div class="footer-about">
              <ul class="social">
                <li>
                  <a href="javascript:;">
                    <i class="lni-facebook-filled"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:;">
                    <i class="lni-twitter-filled"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:;">
                    <i class="lni-instagram-filled"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:;">
                    <i class="lni-linkedin-original"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id="particles-2"></div>
    </footer>
  </div>
</template>

<script>
import freeTrial from "./buttons/free-trial";
import getStart from "./buttons/get-start";
export default {
  components: {
    freeTrial,
    getStart,
  },
};
</script>