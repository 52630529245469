const module ={
    namespaced : true,
    state:{
        contactDatas:{},
        contactElements:{},
    },
    getters:{
        contactDatas(state){
           return state.contactDatas;
        },
        contactElements(state){
            return state.contactElements;
        }
    },
    mutations:{
        setContactDatas(state,payload){
            state.contactDatas = payload;
        },
        setContactElements(state,payload){
            state.contactElements = payload;
        }
    }
}
export default module;