const module={
    namespaced:true,
    state:{
        purchaseDatas:{},
        receiptDatas:{},
        payInDatas:{},
        ledgerDatas:{},
        supplierDatas:{},
        receiptParams:{},
        payInParams:{},
        ledgerParams:{},
    },
    getters:{
        receiptDatas(state){
            return state.receiptDatas;
        },
        payInDatas(state){
            return state.payInDatas;
        },
        ledgerDatas(state){
            return state.ledgerDatas;
        },
        supplierDatas(state){
            return state.supplierDatas;
        },
        purchaseDatas(state){
            return state.purchaseDatas;
        },
        receiptParams(state){
            return state.receiptParams;
        },
        payInParams(state){
            return state.payInParams;
        },
        ledgerParams(state){
            return state.ledgerParams;
        }
    },
    mutations:{
        setLedgerDatas(state,payload){
            state.ledgerDatas = payload;
        },
        setPayInDatas(state,payload){
            state.payInDatas = payload;
        },
        setReceiptDatas(state,payload){
            state.receiptDatas = payload;
        },
        setSupplierDatas(state,payload){
            state.supplierDatas= payload;
        },
        setPurchaseDatas(state,payload){
            state.purchaseDatas = payload;
        },
        setReceiptParams(state,payload){
            state.receiptParams = payload;
        },
        setPayInParams(state,payload){
            state.payInParams = payload;
        },
        setLedgerParams(state,payload){
            state.ledgerParams = payload;
        }
    }
}
export default module