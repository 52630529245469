const module ={
    namespaced : true,
    state:{
        employeeLists:{},
        employeeProfile:{},
        employeePayroll:{},
        employeeLedger:{},
        employeeAttendence:{},
        payrollLists:{},
        payrollElements:{},
        attendenceLists:{},
        monthAttendenceLists:[],
        individualReportParams:{},
        salaryMasterDatas:{},

    },
    getters:{
        employeeLists(state){
            return state.employeeLists;
        },
        payrollLists(state){
            return state.payrollLists;
        },
        payrollElements(state){
            return state.payrollElements;
        },
        attendenceLists(state){
            return state.attendenceLists;
        },
        monthAttendenceLists(state){
            return state.monthAttendenceLists;
        },
        individualReportParams(state){
            return state.individualReportParams;
        },
        salaryMasterDatas(state){
            return state.salaryMasterDatas;
        },
        employeeProfile(state){
            return state.employeeProfile;
        },
        employeePayroll(state){
            return state.employeePayroll;
        },
        employeeLedger(state){
            return state.employeeLedger;
        },
        employeeAttendence(state){
            return state.employeeAttendence;
        },

    },
    mutations:{
        setEmployeeLists(state,payload){
            state.employeeLists = payload;
        },
        setPayrollLists(state,payload){
            state.payrollLists = payload;
        },
        setPayrollElements(state,payload){
            state.payrollElements = payload;
        },
        setAttendenceLists(state,payload){
            state.attendenceLists=payload;
        },
        setMonthAttendenceLists(state,payload){
            state.monthAttendenceLists=payload;
        },
        setIndividualReportParams(state,payload){
            state.individualReportParams = payload;
        },
        setSalaryMasterDatas(state,payload){
            state.salaryMasterDatas = payload;
        },
        setEmployeeProfile(state,payload){
            state.employeeProfile = payload;
        },
        setEmployeePayroll(state,payload){
            state.employeePayroll = payload;
        },
        setEmployeeLedger(state,payload){
            state.employeeLedger = payload;
        },
        setEmployeeAttendence(state,payload){
            state.employeeAttendence = payload;
        }
    }
}
export default module;