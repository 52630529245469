/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import { general } from "./modules/general";
import Sales from "../resources/EbillingProduct/Sales/Services/Store";
import SalesReport from "../resources/EbillingProduct/ReportsGeneral/SalesReport/Services/Store";
import Leads from "../resources/EbillingProduct/Lead/Services/Store";
import Customers from "../resources/EbillingProduct/Customer/Services/Store";
import ContactDetail from "../resources/EbillingProduct/ContactDetail/Services/Store";
import Employees from "../resources/EbillingProduct/HumanResource/Services/Store";
import Suppliers from "../resources/EbillingProduct/Supplier/Services/Store";
import Items from "../resources/EbillingProduct/Item/Services/Store";
import Brand from "../resources/EbillingProduct/Tools/Brand/Services/Store";
import ItemGroup from "../resources/EbillingProduct/ItemGroup/Services/Store";
import UnitType from "../resources/EbillingProduct/UnitType/Services/Store";
import PayIns from "../resources/EbillingProduct/Account/Pay-In/Services/Store";
import AmountTransfer from "../resources/EbillingProduct/Account/AmountTransfer/Services/Store";
import PayOuts from "../resources/EbillingProduct/Account/Pay-Out/Services/Store";
import Banks from "../resources/EbillingProduct/Account/Bank/Services/Store";
import ManageAccount from "../resources/EbillingProduct/Account/ManageAccount/Services/Store";
import Expense from "../resources/EbillingProduct/Account/Expense/Services/Store";
import Payments from "../resources/EbillingProduct/Account/PaymentMethods/Services/Store";
import Purchase from "../resources/EbillingProduct/Purchase/Services/Store";
import Branchs from "../resources/Admin/branch/Services/Store";
import StockDetails from "../resources/EbillingProduct/Inventory/Stock/Services/Store";
import Income from "../resources/EbillingProduct/Account/Income/Services/Store";
import Capital from "../resources/EbillingProduct/Account/Capital/Services/Store";
import StockTransfer from "../resources/EbillingProduct/Inventory/StockTransfer/Services/Store";
import StockAdjustment from "../resources/EbillingProduct/Inventory/StockAdjustment/Services/Store";
import Investment from "../resources/EbillingProduct/Account/Investment/Services/Store";
import Assets from "../resources/EbillingProduct/Account/Assets/Services/Store";
import Loan from "../resources/EbillingProduct/Account/Loan/Services/Store";
import Estimate from "../resources/EbillingProduct/Estimate/Services/Store";
import User from "../resources/Admin/user/Services/Store";
import Role from "../resources/Admin/role/Services/Store";
import OpeningStock from "../resources/EbillingProduct/OpeningStock/Services/Store";
import Feature from "../resources/Feature/Services/Store";
import Cheque from "../resources/EbillingProduct/ChequePrint/Services/Store";
import ExpenseReport from "../resources/EbillingProduct/ReportsGeneral/ExpenseReport/Services/Store";
import IncomeReport from "../resources/EbillingProduct/ReportsGeneral/IncomeReport/Services/Store";
import CapitalReport from "../resources/EbillingProduct/ReportsGeneral/CapitalReport/Services/Store";
import InvestmentReport from "../resources/EbillingProduct/ReportsGeneral/InvestmentReport/Services/Store";
import PurchaseReport from "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/Services/Store";
import LoanReport from "../resources/EbillingProduct/ReportsGeneral/LoanReport/Services/Store";
import ExpiryReport from "../resources/EbillingProduct/ReportsGeneral/ExpiryReport/Services/Store";
import AssetReport from "../resources/EbillingProduct/ReportsGeneral/AssetsReport/Services/Store";
import MinStockReport from "../resources/EbillingProduct/ReportsGeneral/MinStockReport/Services/Store";
import StockReport from "../resources/EbillingProduct/ReportsGeneral/StockReport/Services/Store";
import Ledger from "../resources/EbillingProduct/ReportAccount/LedgerServices/Store";
import TaxExtras from "../resources/EbillingProduct/Setting/Services/Store";
import TaxPayroll from "../resources/EbillingProduct/Account/TaxPayroll/Services/Store";
import TrialReport from "../resources/EbillingProduct/ReportAccount/TrialReport/Services/Store";
import ProfitLossReport from "../resources/EbillingProduct/ReportAccount/ProfitAndLossReport/Services/Store";
import JournalReport from "../resources/EbillingProduct/ReportAccount/JournalReport/Services/Store";
import Dashboard from "../resources/general/Services/Store";
import SalaryReport from "../resources/EbillingProduct/ReportsGeneral/SalaryReport/Services/Store";
import PaymentReport from "../resources/EbillingProduct/ReportsGeneral/PaymentReport/Services/Store";
import BalanceSheet from "../resources/EbillingProduct/ReportAccount/BalanceSheet/Services/Store";
import TaxPayable from "../resources/EbillingProduct/Account/TaxPayable/Services/Store";
import VatReport from "../resources/EbillingProduct/ReportAccount/VatReport/Services/Store";
import Barcode from "../resources/EbillingProduct/Tools/Barcode/Services/Store";
import BalanceAdjustment from "../resources/EbillingProduct/Account/BalanceAdjustment/Services/Store";
import Daybook from "../resources/EbillingProduct/ReportAccount/Daybook/Services/Store";

export const store = new Vuex.Store({
        state: {
        },
        getters: {},
        mutations: {},
        actions: {},
        modules: {
                mixins: general,
                sales: Sales,
                leads: Leads,
                customers: Customers,
                suppliers: Suppliers,
                items: Items,
                payins: PayIns,
                payouts: PayOuts,
                purchase: Purchase,
                branchs: Branchs,
                banks: Banks,
                payments: Payments,
                itemGroups: ItemGroup,
                unitTypes: UnitType,
                brands: Brand,
                amountTransfers: AmountTransfer,
                salesReports: SalesReport,
                stockDetails: StockDetails,
                employees: Employees,
                contactDetails: ContactDetail,
                manageAccounts: ManageAccount,
                expenses: Expense,
                incomes: Income,
                capitals: Capital,
                stockTransfer: StockTransfer,
                stockAdjustment: StockAdjustment,
                investments: Investment,
                loans: Loan,
                assets: Assets,
                estimate: Estimate,
                users: User,
                roles: Role,
                openingStock: OpeningStock,
                features: Feature,
                cheque: Cheque,
                expenseReports:ExpenseReport,
                incomeReports:IncomeReport,
                capitalReports:CapitalReport,
                investmentReports:InvestmentReport,
                purchaseReports:PurchaseReport,
                loanReports:LoanReport,
                expiryReports: ExpiryReport,
                assetsReports:AssetReport,
                minStockReports:MinStockReport,
                stockReports: StockReport,
                ledgers:Ledger,
                taxExtras : TaxExtras,
                taxPayrolls:TaxPayroll,
                trialReport: TrialReport,
                dashboards:Dashboard,
                salaryReports:SalaryReport,
                profitLoss : ProfitLossReport,
                journalReport: JournalReport,
                paymentReport:PaymentReport,
                balanceSheet: BalanceSheet,
                taxPayables:TaxPayable,
                vatReport: VatReport,
                barcode:Barcode,
                adjustments:BalanceAdjustment,
                daybook:Daybook,
        },
});

