const module ={
    namespaced : true,
    state:{
       investmentDataAttributes:{},
       accountWiseDatas:{},
       transactionWiseDatas:{}, 
    },
    getters:{
        investmentDataAttributes(state){
            return state.investmentDataAttributes;
        },
        accountWiseDatas(state){
            return state.accountWiseDatas;
        },
        transactionWiseDatas(state){
            return state.transactionWiseDatas;
        }
    },
    mutations:{
        setInvestmentDataAttributes(state,payload){
            state.investmentDataAttributes = payload;
        },
        setAccountWiseDatas(state,payload){
            state.accountWiseDatas = payload;
        },
        setTransactionWiseDatas(state,payload){
            state.transactionWiseDatas = payload;
        }
    }
}
export default module;