<template>
  <div></div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "load", "keypress"],
      warningTimer: null,
      warning_time: null,
      logout_time: null,
      logoutTimer: null,
      warningModal: false,
      active_time: null,
    };
  },
  mounted() {
    this.active_time = localStorage.getItem("ideal_time");
    this.logout_time = this.active_time * 60 * 1000;
    this.warning_time = this.active_time * 60 * 1000 - 1000;
    let that = this;
    this.events.forEach(function (event) {
      window.addEventListener(event, that.resetTimer);
    });
  },
  methods: {
    setTimers: function () {
      this.warningModal = true;
      this.warningTimer = setTimeout(this.warningMessage, this.warning_time);
      this.logoutTimer = setTimeout(this.logout, this.logout_time);
    },
    resetTimer: function () {
      localStorage.setItem("is_ideal", false);
      clearTimeout(this.warningTimer);
      clearTimeout(this.logoutTimer);
      this.setTimers();
    },
    warningMessage() {
      localStorage.setItem("is_ideal", true);
    },
    logout() {
      if (localStorage.getItem("is_ideal")) {
        axios
          .get(this.$store.state.api_root + "api/logout", {
            headers: { Authorization: `Bearer ${this.$store.state.token}` },
          })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
        this.$store.commit("destroySession", this.$store.state);
      }
    },
  },
};
</script>
