const module ={
    namespaced : true,
    state:{
        paymentDatas:{},
        paymentElements:{},
    },
    getters:{
        paymentDatas(state){
            return state.paymentDatas;
        },
        paymentElements(state){
            return state.paymentElements;
        },
    },
    mutations:{
        setPaymentDatas(state,payload){
            state.paymentDatas = payload;
        },
        setPaymentElements(state,payload){
            state.paymentElements = payload;
        }
    }
}
export default module;