<template>
  <div class="dropdown-menu dropdown-menu-right">
    <div class="dropdown-menu-header">
      <div class="media align-items-center">
        <div class="avatar">
          <img :src="user.image?user.image:'https://via.placeholder.com/500/637382/fff'" class="rounded-circle" alt />
        </div>
        <div class="media-body mg-l-10">
          <h6>{{user.name}}</h6>
          <span v-for="(role,index) in roles" :key="index">{{role.name.replace("-", " ")}}</span>
        </div>
      </div>
    </div>
    <div class="dropdown-menu-body">
      <!-- <a href="javascript:;" class="dropdown-item">
        <i data-feather="user"></i> View Profile
      </a>
      <a href="javascript:;" class="dropdown-item">
        <i data-feather="edit-2"></i> Edit Profile
      </a>
      <a href="javascript:;" class="dropdown-item">
        <i data-feather="briefcase"></i> Account Settings
      </a>
      <a href="javascript:;" class="dropdown-item">
        <i data-feather="shield"></i> Privacy Settings
      </a> -->
      <a href="javascript:;" @click="logout" class="dropdown-item">
        <i data-feather="log-out"></i> Sign Out
      </a>
    </div>
  </div>
</template>

<script>
/* disable eslint */
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["user",'roles']),
  },
  methods: {
    logout() {
      this.$store.dispatch("logOut");
    },
  },
};
</script>