const module ={
    namespaced : true,
    state:{
        extrasLists:{},
    },
    getters:{
       extrasLists(state){
           return state.extrasLists;
       },
    },
    mutations:{
        setExtrasLists(state,payload){
            state.extrasLists = payload;
        }
    }
}
export default module;