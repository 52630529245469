const module ={
    namespaced : true,
    state:{

        trialReportData:{},
              
    },
    getters:{
        trialReportData(state){
            return state.trialReportData;
        },
        
    },
    mutations:{
        setTrialReportData(state,payload){
            state.trialReportData = payload
        }
    }
}
export default module;