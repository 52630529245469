<template>
    <div class="header no-print">
        <div class="header-left">
            <a href="javascript:;" @click="$store.dispatch('toggleSidebar')" class="burger-menu">
                <i class="fas fa-bars"></i>
            </a>
            <div class="header-search">
                <Search />
                <i class="fas fa-search"></i>
            </div>
        </div>
        <div class="header-right">
            <!-- <div class="dropdown dropdown-notification">
                <a href="" class="dropdown-link" data-toggle="dropdown">
                    <img :src="`${icons}/question icon.png`" class="small-notify" style="width: 15px;">
                </a>
                <Notification />
            </div>
            <div class="dropdown dropdown-notification">
                <a href="" class="dropdown-link" data-toggle="dropdown">
                    <img :src="`${icons}/mail-icon.png`" style="width: 20px;">
                </a>
                <Notification />
            </div> 
            <div class="dropdown dropdown-notification">
                <a href="" class="dropdown-link" data-toggle="dropdown">
                    <img :src="`${icons}/material-notifications.png`" style="width: 13px">
                </a>
                <Notification />
            </div> -->
            <div class="dropdown dropdown-loggeduser">
                <a href="" class="dropdown-link" data-toggle="dropdown">
                    <div class="avatar avatar-sm">
                        <img :src="`${icons}/profileIcon.png`">
                    </div><!-- avatar -->
                </a>
                <OnlineUser />
            </div>
        </div>
    </div>
</template>

<script>
    import  Search from './_partials/search';
    import OnlineUser from "./_partials/_dropdown/online-user";
    // import Notification from "./_partials/_dropdown/notification";
    import {mapGetters} from "vuex";
    export default {
        computed: { ...mapGetters(["icons"]), },
        components:{
            // Notification,
            OnlineUser,
            Search
        }
    }
</script>