const module ={
    namespaced : true,
    state:{
        investmentLists:{},
        investmentElements:{},
    },
    getters:{
       investmentLists(state){
           return state.investmentLists;
       },
       investmentElements(state){
           return state.investmentElements;
       }
    },
    mutations:{
        setInvestmentLists(state,payload){
            state.investmentLists = payload;
        },
        setInvestmentElements(state,payload){
            state.investmentElements = payload;
        }
    }
}
export default module;