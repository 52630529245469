const module ={
    namespaced : true,
    state:{
        ledgerLists:{},
        ledgerTotals:{},
        accountTypes:{},
        accounts:{},
    },
    getters:{
        ledgerLists(state){
            return state.ledgerLists;
        },
        ledgerTotals(state){
            return state.ledgerTotals;
        },
        accountTypes(state){
            return state.accountTypes;
        },
        accounts(state){
            return state.accounts;
        }
    },
    mutations:{
        setLedgerLists(state,payload){
            state.ledgerLists = payload;
        },
        setLedgerTotals(state,payload){
            state.ledgerTotals = payload;
        },
        setAccountTypes(state,payload){
            state.accountTypes = payload;
        },
        setAccounts(state,payload){
            state.accounts = payload;
        },
    },
}
export default module;