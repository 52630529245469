const module ={
    namespaced : true,
    state:{

        bikriKhataData:{},
        kharidKhataData:{},
              
    },
    getters:{
        bikriKhataData(state){
            return state.bikriKhataData;
        },
         kharidKhataData(state){
            return state.kharidKhataData;
        },
        
    },
    mutations:{
       setBikriKhataData(state,payload){
            state.bikriKhataData = payload;
        },
        setKharidKhataData(state,payload){
            state.kharidKhataData = payload;
        }
    }
}
export default module;