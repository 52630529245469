const module = {
    namespaced: true,
    state: {


    },
    getters: {

    },
    mutations: {

    },
    actions: {
    }
}
export default module;