<template>
   <div>
       <header class="header-area">
        <div class="navbar-area sticky">
          <frontNav />
        </div>
      </header>
      <div style="margin-top: 5rem;"></div>
      <router-view></router-view>
      <frontFooter />
   </div>
</template>

<script>
/* disable eslint */
import frontNav from "../resources/website/nav";
import frontFooter from "../resources/website/footer";
export default {
  components: {
    frontNav,
    frontFooter,
  }
};
</script>
