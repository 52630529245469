const module ={
    namespaced : true,
    state:{
        expenseLists:{},
        expenseElements:{},
    },
    getters:{
       expenseLists(state){
           return state.expenseLists;
       },
       expenseElements(state){
           return state.expenseElements;
       }
    },
    mutations:{
        setExpenseLists(state,payload){
            state.expenseLists=payload;
        },
        setExpenseElements(state,payload){
            state.expenseElements = payload;
        }
    }
}
export default module;