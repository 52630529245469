const module ={
    namespaced : true,
    state:{
        incomeLists:{},
        incomeElements:{},
    },
    getters:{
       incomeLists(state){
           return state.incomeLists;
       },
       incomeElements(state){
           return state.incomeElements;
       }
    },
    mutations:{
        setIncomeLists(state,payload){
            state.incomeLists=payload;
        },
        setIncomeElements(state,payload){
            state.incomeElements = payload;
        }
    }
}
export default module;