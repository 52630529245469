import Vue from "vue";
import App from "./App.vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';

import { store } from "./store";
import router from "./router";
import VueResource from "vue-resource";
import Vuelidate from "vuelidate";
import VuePageTitle from "vue-page-title";
import VueCarousel from "vue-carousel";
import Pluggin from "../pluggin/custom";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import axios from "axios";
import Vcalendar from "v-calendar";
import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select';


Vue.config.productionTip = false;

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('v-select', vSelect);

import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-select/dist/vue-select.css';
import checkPermissions from "../mixins/permissionsCheck";
Vue.use(Vcalendar);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Pluggin);
Vue.use(VuePageTitle, { router });
Vue.use(Vuelidate);
Vue.use(VueResource);
Vue.use(VueCarousel);
Vue.use(axios);
Vue.use(VueSweetalert2);
Vue.mixin(checkPermissions); 

new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
});
