export default {
    install(Vue) {
        // authorization policy --------- can function added --------
        Vue.prototype.can = function(policy) {
                let permissions = JSON.parse(localStorage.getItem("permissions"));
                if (!localStorage.getItem("token")) return false;
                if (typeof policy == 'string' && permissions.includes(policy)) {
                    return true;
                }
            },
            // convert data in minimum 3 digit
            Vue.prototype.threeDigit = function(number) {
                if(number<10){
                    return  `00${number}`;
                }else if(number>=10 && number<100){
                    return  `0${number}`;
                }else{
                    return number;
                }
            },


            /** 
             * javascript comment 
             * @Author: Ravi SIgdel 
             * @Date: 2021-06-08 18:01:52 
             * @Desc:  this function is used while doing the calculation
             * @param float number
             */
            Vue.prototype.parseDigit = function(number){
                //for now the 4 digits decimal is taken . 
                //for future we can make a setting where user can have desired decimal precision and 
                //save it to local storage and use that value to replace the "4"
                return decimalPrecision(parseFloat(number || 0),4);

            }

            /** 
             * javascript comment 
             * @Author: Ravi SIgdel 
             * @Date: 2021-06-08 18:00:58 
             * @Desc:  this function is used while displaying the records .ie. slips , report
             * @param float number
             */
            Vue.prototype.parseDigitForSlip = function(number)
            {
                return decimalPrecision(parseFloat(number || 0 ),2);
            }
           
            /** 
             * javascript comment 
             * @Author: Ravi SIgdel 
             * @Date: 2021-06-08 18:00:51 
             * @Desc:  Convert the amount to word
             * @param float amount
             */
            Vue.prototype.toWord = function(amount){
                //to make sure the decimal value is rounded off with the precision value before converting to word
                amount = decimalPrecision(parseFloat(amount || 0),2);

                amount = amount.toString();
                let atemp = amount.split(".");
                var whole = convertToWord(amount);

                if(atemp[1]){
                    let decimal_number = atemp[1].split(",").join("");
                    let decNum = decimal_number.substring(0,2);
                    var fraction = convertToWord(decNum);
                    return whole + 'and ' + fraction + 'Paisa ' ;
                
                }   
                return whole;     
               
            }
            /** 
             * javascript comment 
             * @Author: Ravi SIgdel 
             * @Date: 2021-06-08 12:13:46 
             * @Desc:  Remove unnecessary 0 after decimal
             * @param float number
             * @param int decimalPrecision
             */
            function decimalPrecision(number,decimalPrecision = 4)
            {
                let floatedNumber =  parseFloat(number || 0 ).toFixed(decimalPrecision);
                let newNumber = floatedNumber.split(".");
                let fraction = 0;
                //max upto 9 decimal precision
                let dec = [
                    9,99,999,9999,99999,999999,9999999,99999999,9999999
                ];
                if(newNumber[1])
                {
                    if(parseInt(newNumber[1]) > 0){
                    let decimalNumber = newNumber[1].split("");
                    decimalNumber.forEach((element,index) => {
                        if(newNumber[1] <= dec[index]){
                            fraction = newNumber[1].substring(0,index+1);
                        }
                    });
                    }                    
                }
                return parseFloat(newNumber[0] + '.' + fraction) ;
            }
            /** 
             * javascript comment 
             * @Author: Ravi SIgdel 
             * @Date: 2021-06-08 12:12:57 
             * @Desc: Convert the amount to word 
             */
            function convertToWord(amount){
               let words = new Array();
                words[0] = '';
                words[1] = 'One';
                words[2] = 'Two';
                words[3] = 'Three';
                words[4] = 'Four';
                words[5] = 'Five';
                words[6] = 'Six';
                words[7] = 'Seven';
                words[8] = 'Eight';
                words[9] = 'Nine';
                words[10] = 'Ten';
                words[11] = 'Eleven';
                words[12] = 'Twelve';
                words[13] = 'Thirteen';
                words[14] = 'Fourteen';
                words[15] = 'Fifteen';
                words[16] = 'Sixteen';
                words[17] = 'Seventeen';
                words[18] = 'Eighteen';
                words[19] = 'Nineteen';
                words[20] = 'Twenty';
                words[30] = 'Thirty';
                words[40] = 'Forty';
                words[50] = 'Fifty';
                words[60] = 'Sixty';
                words[70] = 'Seventy';
                words[80] = 'Eighty';
                words[90] = 'Ninety';
                amount = amount.toString();
                let atemp = amount.split(".");
                let number = atemp[0].split(",").join("");        
                let n_length = number.length;
               
                let words_string = "";
                if (n_length <= 9) {
                    let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
                    let received_n_array = new Array();
                    for (let i = 0; i < n_length; i++) {
                        received_n_array[i] = number.substr(i, 1);
                    }
                    for (let i = 9 - n_length, j = 0; i < 9; i++, j++) {
                        n_array[i] = received_n_array[j];
                    }
                    for (let i = 0, j = 1; i < 9; i++, j++) {
                        if (i == 0 || i == 2 || i == 4 || i == 7) {
                            if (n_array[i] == 1) {
                                n_array[j] = 10 + parseInt(n_array[j]);
                                n_array[i] = 0;
                            }
                        }
                    }
                    let value = "";
                    for (let i = 0; i < 9; i++) {
                        if (i == 0 || i == 2 || i == 4 || i == 7) {
                            value = n_array[i] * 10;
                        } else {
                            value = n_array[i];
                        }
                        if (value != 0) {
                            words_string += words[value] + " ";
                        }
                        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                            words_string += "Crores ";
                        }
                        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                            words_string += "Lakhs ";
                        }
                        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                            words_string += "Thousand ";
                        }
                        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                            //if the give number doesnt have decimal then inlucde And on hundred else dont
                    
                            if(!atemp[1]){
                              
                                words_string += "Hundred and ";
                            }else{
                               words_string += "Hundred "; 
                            }
                        } else if (i == 6 && value != 0) {
                            words_string += "Hundred ";
                        }
                    }
                    
                    words_string = words_string.split("  ").join(" ");
                }
                return words_string;
            }


        }
}