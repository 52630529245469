const module ={
    namespaced : true,
    state:{
        amountTransferDatas:{},
        elementDatas:{},
        slipData:{},

    },
    getters:{
       amountTransferDatas(state){
           return state.amountTransferDatas;
       },
       elementDatas(state){
           return state.elementDatas;
       },
       slipData(state){
           return state.slipData;
       }
    },
    mutations:{
       setAmountTransferDatas(state,payload){
           state.amountTransferDatas = payload;
       },
       setElementDatas(state,payload){
           state.elementDatas = payload;
       },
       setSlipData(state,payload){
           state.slipData = payload;
       }
    }
}
export default module;