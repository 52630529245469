const module ={
    namespaced : true,
    state:{
        employees:[],
        contact_persons:{},
        lead:{},
        leadDatas:{},
        leadLists:{},
        leadElements:{},
        leadAllLists:{},
        followUpDatas:{},
        followUpElements:{},
        followUpEditElements:{},
        estimateDatas:{},
    },
    getters:{
        employees(state){
            return state.employees;
        },
        contact_persons(state){
            return state.contact_persons;
        },
        lead(state){
            return state.lead;
        },
        leadDatas(state){
            return state.leadDatas;
        },
        leadLists(state){
            return state.leadLists;
        },
        leadElements(state){
            return state.leadElements;
        },
        leadAllLists(state){
            return state.leadAllLists;
        },
        followUpDatas(state){
            return state.followUpDatas;
        },
        followUpElements(state){
            return state.followUpElements;
        },
        followUpEditElements(state){
            return state.followUpEditElements;
        },
        estimateDatas(state){
            return state.estimateDatas;
        }
    },
    mutations:{
        setEmployees(state,payload){
            state.employees =payload;
        },
        setContactPersons(state,payload){
            state.contact_persons = payload;
        },
        setLead(state,payload){
            state.lead = payload;
        },
        setLeadDatas(state,payload){
            state.leadDatas = payload;
        },
        setLeadLists(state,payload){
            state.leadLists = payload;
        },
        setLeadElements(state,payload){
            state.leadElements = payload;
        },
        setLeadAllLists(state,payload){
            state.leadAllLists = payload;
        },
        setFollowUpDatas(state,payload){
            state.followUpDatas = payload;
        },
        setFollowUpElements(state,payload){
            state.followUpElements = payload;
        },
        setFollowUpEditElements(state,payload){
            state.followUpEditElements = payload;
        },
        setEstimateDatas(state,payload){
            state.estimateDatas = payload;
        }
    }
}
export default module;