const module ={
    namespaced : true,
    state:{
       payInDatas:{},
       payInElements:{},
       payInReceiptData:{},
    },
    getters:{
       payInDatas(state){
           return state.payInDatas;
       },
       payInElements(state){
           return state.payInElements;
       },
       payInReceiptData(state){
        return state.payInReceiptData;
    }
    },
    mutations:{
        setPayInDatas(state,payload){
            state.payInDatas = payload;
        },
        setPayInElements(state,payload){
            state.payInElements = payload;
        },
        setPayInReceiptData(state,payload){
            state.payInReceiptData = payload;
        }
    }
}
export default module;