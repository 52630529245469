const module ={
    namespaced : true,
    state:{
       payOutDatas:{},
       payOutElements:{},
       payOutReceiptData:{},

    },
    getters:{
       payOutDatas(state){
           return state.payOutDatas;
       },
       payOutElements(state){
           return state.payOutElements;
       },
       payOutReceiptData(state){
           return state.payOutReceiptData;
       }
    },
    mutations:{
        setPayOutDatas(state,payload){
            state.payOutDatas = payload;
        },
        setPayOutElements(state,payload){
            state.payOutElements = payload;
        },
        setPayOutReceiptData(state,payload){
            state.payOutReceiptData = payload;
        }
    }
}
export default module;