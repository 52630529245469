const module = {
    namespaced: true,
    state: {
        billWiseDatas: {},
        billWiseAttributes: {},
        branchWiseDatas: {},
        branchWiseAttributes: {},
        dayWiseAtrributes: {},
        dayWiseDatas: {},
        monthWiseDatas: {},
        monthWiseAttributes: {},
        customerWiseDatas: {},
        customerWiseAttributes: {}
    },
    getters: {
        billWiseDatas(state) {
            return state.billWiseDatas;
        },
        billWiseAttributes(state) {
            return state.billWiseAttributes;
        },
        branchWiseAttributes(state) {
            return state.branchWiseAttributes;
        },
        branchWiseDatas(state) {
            return state.branchWiseDatas;
        },
        dayWiseAtrributes(state) {
            return state.dayWiseAtrributes;
        },
        dayWiseDatas(state) {
            return state.dayWiseDatas;
        },
        monthWiseDatas(state) {
            return state.monthWiseDatas;
        },
        monthWiseAttributes(state) {
            return state.monthWiseAttributes;
        },
        customerWiseDatas(state) {
            return state.customerWiseDatas;
        },
        customerWiseAttributes(state) {
            return state.customerWiseAttributes;
        }

    },
    mutations: {
        setBillWiseDatas(state, payload) {
            state.billWiseDatas = payload;
        },
        setBillWiseAttributes(state, payload) {
            state.billWiseAttributes = payload;
        },
        setBranchWiseDatas(state, payload) {
            state.branchWiseDatas = payload;
        },
        setBranchWiseAttributes(state, payload) {
            state.branchWiseAttributes = payload;
        },
        setDayWiseAtrributes(state, payload) {
            state.dayWiseAtrributes = payload;
        },
        setDayWiseDatas(state, payload) {
            state.dayWiseDatas = payload;
        },
        setMonthWiseDatas(state, payload) {
            state.monthWiseDatas = payload;
        },
        setMonthWiseAttributes(state, payload) {
            state.monthWiseAttributes = payload;
        },
        setCustomerWiseDatas(state, payload) {
            state.customerWiseDatas = payload;
        },
        setCustomerWiseAttributes(state, payload) {
            state.customerWiseAttributes = payload;
        }
    }
}
export default module;