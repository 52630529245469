<template>
  <div class="sidebar">
    <div class="sidebar-header">
      <div>
        <router-link
          to="/home"
          active-class="active"
          exact
          :style="{ cursor: 'pointer' }"
          class="sidebar-logo"
          tag="a"
        >
          <span v-if="authData.logo != null">
            <img :src="authData.logo.logo" height="54" width="auto" margin="2px 0" padding="0" />
          </span>
          <span v-else>
            <img src="/assets/img/logo.png" height="54" width="auto" margin="2px 0" padding="0" />
          </span>
        </router-link>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav nav-sidebar" v-for="menu in menus" :key="menu.menu_slug">
        <li class="nav-label">
          <label class="content-label">{{ menu.menu }}</label>
        </li>
        <li
          class="nav-item"
          v-for="child in menu.child"
          :key="child.slug"
          :class="{ 'show':selectedMenuIndex == child.slug}"
        >
          <span v-if="child.no_child">
            <router-link
              :to="child.child[0].route"
              active-class="active"
              exact
              :style="{ cursor: 'pointer' }"
              class="nav-link"
              tag="a"
              @click="openSubChild(child.child[0].slug)"
            >
              <span class="menu-icon">
                <!-- if sub child has icon then we will need this -->
                <i :class="child.icon"></i>
              </span>
              {{ child.title }}
            </router-link>
          </span>
          <span v-if="!child.no_child">
            <a href="javascript:;" class="nav-link with-sub" @click="openMenu(child.slug)">
              <span class="menu-icon">
                <i :class="child.icon"></i>
              </span>
              {{ child.title }}
            </a>
            <nav class="nav nav-sub" v-for="subChild in child.child" :key="subChild.slug">
              <router-link
                :to="subChild.route"
                active-class="active"
                exact
                :style="{ cursor: 'pointer' }"
                class="nav-sub-link"
                tag="a"
                @click="openSubChild(child.slug)"
              >
                <span class="menu-icon">
                  <!-- if sub child has icon then we will need this -->
                  <!-- <i :class="subChild.icon"></i> -->
                </span>
                {{ subChild.title }}
              </router-link>
            </nav>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
// import Sidebarjson from "./Constants/NewSidebar.json";
export default {
  data() {
    return {
      selectedMenuIndex: "",
      menus: [],
    };
  },
  computed: {
    ...mapGetters(["logo", "modules", "authData"]),
  },
  beforeMount() {
    this.menus = JSON.parse(localStorage.getItem("sidebar"));
    let currentRoute = this.$route;
    this.menus.forEach((menu) => {
      menu.child.forEach((child) => {
        child.child.forEach((subChild) => {
          if (subChild.route == currentRoute.path) {
            this.selectedMenuIndex = child.slug;
          }
        });
      });
    });
  },
  methods: {
    openMenu(slug) {
      if (this.selectedMenuIndex == slug) {
        this.selectedMenuIndex = "";
      } else {
        this.selectedMenuIndex = slug;
      }
    },
    openSubChild(slug) {
      this.selectedMenuIndex == slug;
    },
  },
};
</script>
<style>
.show .nav-link.with-sub::after {
  transform: rotate(135deg);
}
.sidebar .sidebar-body ul li span {
  display: unset;
}
</style>
