const module ={
    namespaced : true,
    state:{
        accountLists:{},
        accountElements:{},
    },
    getters:{
       accountLists(state){
           return state.accountLists;
       },
       accountElements(state){
           return state.accountElements;
       }
    },
    mutations:{
        setAccountLists(state,payload){
            state.accountLists=payload;
        },
        setAccountElements(state,payload){
            state.accountElements = payload;
        }
    }
}
export default module;