<template>
  <div>
    <sideBar />
    <div class="content content-page">
      <!-- <div class="text-center print-detail-only">
        <div class="position-fixed bracode-no-display">
          <p class="font-xxxl mb-0">{{authData.org_detail.name}}</p>
          <p class="mb-0">
            Tel No: {{authData.org_detail.phone}} / {{authData.org_detail.address}}
            <br />
            PAN: {{authData.org_detail.pan_vat}}
          </p>
        </div>
      </div> -->
      <TopHeader />
      <router-view></router-view>
    </div>
    <AutoLogout />
  </div>
</template>


<script>
/* disable eslint */
import sideBar from "../resources/components/NewSideBar";
import TopHeader from "../resources/components/top-header";
import AutoLogout from "../resources/AutoLogout/AutoLogout";
import { mapGetters } from "vuex";
export default {
  name: "app",
  components: {
    sideBar,
    TopHeader,
    AutoLogout,
  },
  computed: {
    ...mapGetters(["authData"]),
  },
  mounted(){
    this.$store.commit("setSideBar",localStorage.getItem('sidebar'));
    this.$store.commit("setPermissions",localStorage.getItem('permissions'));
  },
};
</script>
