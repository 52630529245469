let urlhelper = {
      //   'hostUrl': "http://localhost:8000/",
        'hostUrl': "https://dgtrade.api.skhata.com/",
      //   'hostUrl': "https://bluestarexpress.api.skhata.com/",
      //   'hostUrl': "https://smarteduservice.api.skhata.com/",
      // 'hostUrl': "https://ugrachandielectronics.api.skhata.com/",
      // 'hostUrl': "https://ilamdgtrade.api.skhata.com/",
      // 'hostUrl': "https://amrawati.api.skhata.com/",
      // 'hostUrl': "https://newboilercoldstore.api.skhata.com/",
      // 'hostUrl': "https://company2079.api.skhata.com/",
      //   'hostUrl': "https://dkfood.api.skhata.com/",
      // 'hostUrl': "http://dgtrade.api.skhata.com/",
      //   'hostUrl': "https://wrealestate.api.skhata.com/",
      // 'hostUrl':"https://united.api.skhata.com/",
   //   'hostUrl':"https://smartpasal.api.skhata.com/",
      //'hostUrl':"https://intotrend.api.skhata.com/",
   //   'hostUrl':"https://shoporder.api.skhata.com/",
//   'hostUrl':"https://alfatrue78.api.skhata.com/",
//   'hostUrl':"http://dzinefolio.api.skhata.com/",
   // 'hostUrl': "http://nepalthopa.api.softechpark.com.np/"
   // 'hostUrl': "http://omshantitraders.api.softechpark.com.np/"
   // 'hostUrl': "https://product.api.skhata.com/",
   // 'hostUrl': "http://bdg.api.softechpark.com.np/",
//     'hostUrl': "http://bdg.api.skhata.com/",
    //'hostUrl': "http://rms.sei.demo.api.softechpark.com.np/",
   // 'hostUrl': "http://rms.demo.api.softechpark.com.np/",
   //'hostUrl': "https://mandp.api.skhata.com/",
      // 'hostUrl': "https://ntshub.api.skhata.com/",
   // 'hostUrl' : "http://ebilling.api.stphost.com/",
   // 'hostUrl': "https://product.api.skhata.com/",
   // 'hostUrl': "http://ebilling.v0.2.api.stphost.com/",
   // 'hostUrl' : "http://192.168.0.105:8000/",
    //'hostUrl': "http://developer.api.skhata.com/",
   // 'hostUrl': "http://company2078.api.skhata.com/",
   // 'hostUrl': "http://dfpl.api.skhata.com/",
   // 'hostUrl': "http://omshanti.api.skhata.com/",
   // 'hostUrl': "https://product.api.skhata.com/",
   // 'hostUrl': "https://dtf5ypyannuqf.cloudfront.net/",
   
};

module.exports = urlhelper;