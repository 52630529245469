const module ={
    namespaced: true,
    state:{
        purchaseItem:{},
        purchaseItemDetails:[],
        searchAutoFocus:false,
        branchId:'',
        itemGroups:[],
        dataSets:[],
        slipSettings:[],
        itemSearchLoading: false,
        extraFields:[],
        selectedExtra:[],
        selectedNewExtra:[],
        itemToBePurchased:[], //list of items loaded at begning of page 
        slipType:''

    },
    getters:{
        purchaseItemDetails(state){
            return state.purchaseItemDetails;
        },
        purchaseItem(state){
            return state.purchaseItem;
        },
        searchAutoFocus(state){
            return state.searchAutoFocus;
        },
       
        branchId(state){
            return state.branchId;
        },
        itemGroups(state){
            return state.itemGroups;
        },
        dataSets(state){
            return state.dataSets;
        },
        slipSettings(state){
            return state.slipSettings;
        },
        itemSearchLoading(state){
            return state.itemSearchLoading;
        },
        extraFields(state){
            return state.extraFields;
        },
        selectedExtra(state){
            return state.selectedExtra;
        },
        itemToBePurchased(state){
            return state.itemToBePurchased;
        },
        selectedNewExtra(state){
            return state.selectedNewExtra;
        },
        slipType(state){
            return state.slipType;
        }
        
    },
    mutations:{
        setPurchaseItemDetails(state,payload){
            state.purchaseItemDetails = payload;
        },
        setPurchaseItem(state,payload){
            state.purchaseItem = payload; 
        },
       
        setBranchId(state,id){
            state.branchId = id;
        },
        setItemGroups(state,payload){
            state.itemGroups = payload;
        },
        setDataSets(state,payload){
            state.dataSets = payload;
        },
        setSlipSettings(state,payload){
            state.slipSettings = payload;
        },
        setItemSearchLoading(state,boolean){
            state.itemSearchLoading = boolean;
        },
        setExtraFields(state,payload){
            if(state.extraFields.length > 0 ){
                state.extraFields.push(payload);
            }else{
                state.extraFields = [payload];
            }
        },
        setSelectedExtra(state,payload){
            state.selectedExtra = payload;
        },
        setItemToBePurchased(state,payload){
            state.itemToBePurchased = payload;
        },
        setSelectedNewExtra(state,payload){
            if(state.selectedNewExtra.length > 0 ){
                state.selectedNewExtra.push(payload);
            }else{
                state.selectedNewExtra = [payload];
            }
        },
        removeExtraFields(state){
            state.extraFields = [];
        },
        setExtraFieldsFromPurchaseOrder(state,payload){
            state.extraFields = payload;
        },
        setSlipType(state,payload){
            state.slipType = payload;
        },
        setSearchAutoFocus(state,boolean){
            state.searchAutoFocus = boolean;
        }
    },
    actions:{
    }
}
export default module;