const module ={
    namespaced : true,
    state:{
        userLists:{},
        userElements:{},
    },
    getters:{
       userLists(state){
           return state.userLists;
       },
       userElements(state){
           return state.userElements;
       }
    },
    mutations:{
        setUserLists(state,payload){
            state.userLists=payload;
        },
        setUserElements(state,payload){
            state.userElements = payload;
        }
    }
}
export default module;