const module ={
    namespaced : true,
    state:{
       paymentDataAttrbutes:{},
       branchWiseDatas:{},
       transactionWiseDatas:{}, 
       methodWiseDatas:{},
       dayWiseDatas:{},
       monthWiseDatas:{},
    },
    getters:{
        paymentDataAttrbutes(state){
            return state.paymentDataAttrbutes;
        },
        branchWiseDatas(state){
            return state.branchWiseDatas;
        },
        methodWiseDatas(state){
            return state.methodWiseDatas;
        },
        transactionWiseDatas(state){
            return state.transactionWiseDatas;
        },
        dayWiseDatas(state){
            return state.dayWiseDatas;
        },
        monthWiseDatas(state){
            return state.monthWiseDatas;
        }
    },
    mutations:{
        setPaymentDataAttrbutes(state,payload){
            state.paymentDataAttrbutes = payload;
        },
        setBranchWiseDatas(state,payload){
            state.branchWiseDatas = payload;
        },
        setMethodWiseDatas(state,payload){
            state.methodWiseDatas = payload;
        },
        setTransactionWiseDatas(state,payload){
            state.transactionWiseDatas = payload;
        },
        setMonthWiseDatas(state,payload){
            state.monthWiseDatas=payload;
        },
        setDayWiseDatas(state,payload){
            state.dayWiseDatas=payload;
        }
    }
}
export default module;