const module ={
    namespaced : true,
    state:{
        capitalLists:{},
        capitalElements:{},
    },
    getters:{
       capitalLists(state){
           return state.capitalLists;
       },
       capitalElements(state){
           return state.capitalElements;
       }
    },
    mutations:{
        setCapitalLists(state,payload){
            state.capitalLists = payload;
        },
        setCapitalElements(state,payload){
            state.capitalElements = payload;
        }
    }
}
export default module;