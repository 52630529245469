/* eslint-disable */
import axios from "axios";
import {hostUrl} from "../config/UrlConfig";

export const general = {
  state: {
    processing: false,
    token: localStorage.getItem("token"),
    authData: localStorage.getItem("authData"),
    permissions:localStorage.getItem("permissions"),
    sidebar: JSON.parse(localStorage.getItem("sidebar")),
    barcodeData: "",
    currentComponent: "",
    eventMessage: "",
    messageState: false,
    origin: `${window.location.origin}/`,   
    
    api_root: hostUrl,

    apiUrl: "",
    dataLists: [],
    dataLists1: [],
    dataLists2: [],
    dataLists3: [],
    dataLists4: [],
    dataLists5: [],
    dataLists6: [],
    dataLists7: [],
    dataLists8: [],
    dataLists9: [],
    pagination: [],
    pagination1: [],
    pagination2: [],
    pagination3: [],
    pagination4: [],
    pagination5: [],
    pagination6: [],
    pagination7: [],
    pagination8: [],
    pagination9: [],
    dataId: "",
    removeList: [],
    modalId: "",
    modalMode: "",
    modalState: false,
    toggleSidebar: false,
  },
  getters: {
    barcodeData(state) {
      return state.barcodeData;
    },

    authData(state) {
      return JSON.parse(state.authData);
    },
    user(state) {
      let data = JSON.parse(state.authData);
      return data.user;
    },
    roles(state) {
      let data = JSON.parse(state.authData);
      return data.roles;
    },
    permissions(state) {
      return state.permissions;
    },
    sidebar(state){
      return JSON.parse(state.sidebar);
    },
    modules(state) {
      let data = JSON.parse(state.authData);
      return data.modules;
    },
    token(state) {
      return state.token;
    },
    today() {
      return new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-");
    },
    processing(state) {
      return state.processing;
    },
    currentComponent(state) {
      return state.currentComponent;
    },
    fileLocation(state) {
      return `${state.origin}assets/`;
    },
    icons(state) {
      return `${state.origin}assets/icon/`;
    },
    logo(state) {
      return `${state.origin}assets/img/logo.png`;
    },
    thumb(state) {
      return `${state.origin}assets/img/thumb.png`;
    },
    origin(state) {
      return state.origin;
    },
    apiRoot(state) {
      return state.api_root;
    },
    eventMessage(state) {
      return state.eventMessage;
    },
    messageState(state) {
      return state.messageState;
    },
    apiUrl(state) {
      return state.apiUrl;
    },
    dataLists(state) {
      return state.dataLists;
    },
    dataLists1(state) {
      return state.dataLists1;
    },
    dataLists2(state) {
      return state.dataLists2;
    },
    dataLists3(state) {
      return state.dataLists3;
    },
    dataLists4(state) {
      return state.dataLists4;
    },
    dataLists5(state) {
      return state.dataLists5;
    },
    dataLists6(state) {
      return state.dataLists6;
    },
    dataLists7(state) {
      return state.dataLists7;
    },
    dataLists8(state) {
      return state.dataLists8;
    },
    dataLists9(state) {
      return state.dataLists9;
    },
    pagination(state) {
      return state.pagination;
    },
    pagination1(state) {
      return state.pagination1;
    },
    pagination2(state) {
      return state.pagination2;
    },
    pagination3(state) {
      return state.pagination3;
    },
    pagination4(state) {
      return state.pagination4;
    },
    pagination5(state) {
      return state.pagination5;
    },
    pagination6(state) {
      return state.pagination6;
    },
    pagination7(state) {
      return state.pagination7;
    },
    pagination8(state) {
      return state.pagination8;
    },
    pagination9(state) {
      return state.pagination9;
    },
    dataId(state) {
      return state.dataId;
    },
    removeList(state) {
      return state.removeList;
    },
    modalId(state) {
      return state.modalId;
    },
    modalMode(state) {
      return state.modalMode;
    },
    modalState(state) {
      return state.modalState;
    },
    toggleSidebar(state) {
      return state.toggleSidebar;
    },
  },
  mutations: {
    setBarcodeData(state, data) {
      return (state.barcodeData = data);
    },

    setAuthData(state, data) {
      return (state.userData = data);
    },
    setOrigin(state, origin) {
      state.origin = origin;
    },
    setApiUrl(state, url) {
      state.apiUrl = state.api_root + "" + url;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPermissions(state,permissions){
      state.permissions = permissions;
    },
    setSideBar(state,sidebar){
      state.sidebar = sidebar;
    },
    enableProcessing(state) {
      state.processing = true;
    },
    disableProcessing(state) {
      state.processing = false;
    },
    setEventMessage(state, message) {
      state.eventMessage = message;
    },
    setCurrentComponent(state, component) {
      state.currentComponent = component;
    },
    setDataLists(state, payload) {
      state.dataLists = payload;
    },
    setDataLists1(state, payload) {
      state.dataLists1 = payload;
    },
    setDataLists2(state, payload) {
      state.dataLists2 = payload;
    },
    setDataLists3(state, payload) {
      state.dataLists3 = payload;
    },
    setDataLists4(state, payload) {
      state.dataLists4 = payload;
    },
    setDataLists5(state, payload) {
      state.dataLists5 = payload;
    },
    setDataLists6(state, payload) {
      state.dataLists6 = payload;
    },
    setDataLists7(state, payload) {
      state.dataLists7 = payload;
    },
    setDataLists8(state, payload) {
      state.dataLists8 = payload;
    },
    setDataLists9(state, payload) {
      state.dataLists9 = payload;
    },
    setDataId(state, id) {
      state.dataId = id;
    },
    setRemoveList(state, id) {
      state.removeList.push(id);
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
    setPagination1(state, payload) {
      state.pagination1 = payload;
    },
    setPagination2(state, payload) {
      state.pagination2 = payload;
    },
    setPagination3(state, payload) {
      state.pagination3 = payload;
    },
    setPagination4(state, payload) {
      state.pagination4 = payload;
    },
    setPagination5(state, payload) {
      state.pagination5 = payload;
    },
    setPagination6(state, payload) {
      state.pagination6 = payload;
    },
    setPagination7(state, payload) {
      state.pagination7 = payload;
    },
    setPagination8(state, payload) {
      state.pagination8 = payload;
    },
    setPagination9(state, payload) {
      state.pagination9 = payload;
    },
  
    dataFromUrl(state, url) {
      this.commit("enableProcessing");
      state.dataLists = [];
      state.pagination = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    dataFromUrl1(state, url) {
      this.commit("enableProcessing");
      state.dataLists1 = [];
      state.pagination1 = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse1", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    dataFromUrl2(state, url) {
      this.commit("enableProcessing");
      state.dataLists2 = [];
      state.pagination2 = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse2", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    dataFromUrl3(state, url) {
      this.commit("enableProcessing");
      state.dataLists3 = [];
      state.pagination3 = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse3", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    dataFromUrl4(state, url) {
      this.commit("enableProcessing");
      state.dataLists4 = [];
      state.pagination4 = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse4", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    dataFromUrl5(state, url) {
      this.commit("enableProcessing");
      state.dataLists5 = [];
      state.pagination5 = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse5", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    dataFromUrl6(state, url) {
      this.commit("enableProcessing");
      state.dataLists6 = [];
      state.pagination6 = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse6", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    dataFromUrl7(state, url) {
      this.commit("enableProcessing");
      state.dataLists7 = [];
      state.pagination7 = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse7", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    dataFromUrl8(state, url) {
      this.commit("enableProcessing");
      state.dataLists8 = [];
      state.pagination8 = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse8", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    dataFromUrl9(state, url) {
      this.commit("enableProcessing");
      state.dataLists9 = [];
      state.pagination9 = [];
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse9", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData(state, url) {
      this.commit("enableProcessing");
      state.dataLists = [];
      state.pagination = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData1(state, url) {
      this.commit("enableProcessing");
      state.dataLists1 = [];
      state.pagination1 = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse1", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData2(state, url) {
      this.commit("enableProcessing");
      state.dataLists2 = [];
      state.pagination2 = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse2", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData3(state, url) {
      this.commit("enableProcessing");
      state.dataLists3 = [];
      state.pagination3 = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse3", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData4(state, url) {
      this.commit("enableProcessing");
      state.dataLists4 = [];
      state.pagination4 = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse4", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData5(state, url) {
      this.commit("enableProcessing");
      state.dataLists5 = [];
      state.pagination5 = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse5", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData6(state, url) {
      this.commit("enableProcessing");
      state.dataLists6 = [];
      state.pagination6 = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse6", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData7(state, url) {
      this.commit("enableProcessing");
      state.dataLists7 = [];
      state.pagination7 = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse7", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData8(state, url) {
      this.commit("enableProcessing");
      state.dataLists8 = [];
      state.pagination8 = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse8", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    getData9(state, url) {
      this.commit("enableProcessing");
      state.dataLists9 = [];
      state.pagination9 = [];
      axios
        .get(state.api_root + "" + url, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((res) => {
          this.commit("manageResponse9", res.data);
        })
        .catch((error) => {
          this.commit("manageErrors", error);
        });
    },
    addData(state, payload) {
      if (confirm(`Are you sure want to submit?`)) {
        if (state.apiUrl) {
          this.commit("enableProcessing");
          axios
            .post(state.apiUrl, payload, {
              headers: { Authorization: `Bearer ${state.token}` },
            })
            .then((res) => {
              state.messageState = true;
              this.commit("manageResponse", res.data);
            })
            .catch((error) => {
              this.commit("manageErrors", error);
            });
        } else {
          this.commit("setEventMessage", "Please set url first.");
          state.messageState = true;
        }
      } else {
        return false;
      }
    },
    sendData(state, payload) {
      if (state.apiUrl) {
        this.commit("enableProcessing");
        axios
          .post(state.apiUrl, payload, {
            headers: { Authorization: `Bearer ${state.token}` },
          })
          .then((res) => {
            state.messageState = true;
            this.commit("manageResponse", res.data);
          })
          .catch((error) => {
            this.commit("manageErrors", error);
          });
      } else {
        this.commit("setEventMessage", "Please set url first.");
        state.messageState = true;
      }
    },
    updateData(state, payload) {
      if (confirm(`Are you sure want to submit?`)) {
        this.commit("enableProcessing");
        if (state.apiUrl) {
          axios
            .patch(state.apiUrl, payload, {
              headers: { Authorization: `Bearer ${state.token}` },
            })
            .then((res) => {
              state.messageState = true;
              this.commit("manageResponse", res.data);
            })
            .catch((error) => {
              this.commit("manageErrors", error);
            });
        } else {
          this.commit("setEventMessage", "Please set url first.");
        }
      } else {
        return false;
      }
    },
    patchData(state, payload) {
      if (state.apiUrl) {
        this.commit("enableProcessing");
        axios
          .patch(state.apiUrl, payload, {
            headers: { Authorization: `Bearer ${state.token}` },
          })
          .then((res) => {
            state.messageState = true;
            this.commit("manageResponse", res.data);
          })
          .catch((error) => {
            this.commit("manageErrors", error);
          });
      } else {
        this.commit("setEventMessage", "Please set url first.");
      }
    },
    dropRecord(state, url) {
      if (confirm("Are you sure?")) {
        this.commit("enableProcessing");
        axios
          .delete(state.api_root + "" + url, {
            headers: { Authorization: `Bearer ${state.token}` },
          })
          .then((res) => {
            state.messageState = true;
            this.commit("manageResponse", res.data);
          })
          .catch((error) => {
            this.commit("manageErrors", error);
          });
      } else {
        return false;
      }
    },
    manageErrors(state, error) {
      this.commit("disableProcessing");
      if (error.response.data.errors) {
        let message = "";
        for (let err in error.response.data.errors) {
          //console.log(error.response.data.errors[err][0]);``
          message = `${message} ${error.response.data.errors[err][0]}`;
        }
        state.messageState = true;
        this.commit("setEventMessage", message);
      }
      // if (error.response) {
      //     console.log('data',error.response.data);
      //     console.log('status',error.response.status);
      //     console.log('headers',error.response.headers);
      // } else if (error.request) {
      //     console.log('request',error.request);
      // } else {
      //     console.log('Error', error.message);
      // }
      // console.log('config',error.config);
    },
    manageResponse(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists = [];
          state.pagination = [];
          this.commit("setDataLists", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists = [];
          state.pagination = [];
          this.commit("setDataLists", data.data);
        }
      }
    },
    manageResponse1(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists1 = [];
          state.pagination1 = [];
          this.commit("setDataLists1", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination1", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists1 = [];
          state.pagination1 = [];
          this.commit("setDataLists1", data.data);
        }
      }
    },
    manageResponse2(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists2 = [];
          state.pagination2 = [];
          this.commit("setDataLists2", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination2", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists2 = [];
          state.pagination2 = [];
          this.commit("setDataLists2", data.data);
        }
      }
    },
    manageResponse3(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists3 = [];
          state.pagination3 = [];
          this.commit("setDataLists3", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination3", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists3 = [];
          state.pagination3 = [];
          this.commit("setDataLists3", data.data);
        }
      }
    },
    manageResponse4(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists4 = [];
          state.pagination4 = [];
          this.commit("setDataLists4", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination4", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists4 = [];
          state.pagination4 = [];
          this.commit("setDataLists4", data.data);
        }
      }
    },
    manageResponse5(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists5 = [];
          state.pagination5 = [];
          this.commit("setDataLists5", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination5", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists5 = [];
          state.pagination5 = [];
          this.commit("setDataLists5", data.data);
        }
      }
    },
    manageResponse6(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists6 = [];
          state.pagination6 = [];
          this.commit("setDataLists6", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination6", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists6 = [];
          state.pagination6 = [];
          this.commit("setDataLists6", data.data);
        }
      }
    },
    manageResponse7(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists7 = [];
          state.pagination7 = [];
          this.commit("setDataLists7", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination7", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists7 = [];
          state.pagination7 = [];
          this.commit("setDataLists7", data.data);
        }
      }
    },
    manageResponse8(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists8 = [];
          state.pagination8 = [];
          this.commit("setDataLists8", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination8", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists8 = [];
          state.pagination8 = [];
          this.commit("setDataLists8", data.data);        

        }
      }
    },
    manageResponse9(state, data) {
      this.commit("disableProcessing");
      if (data.error_message) {
        this.commit("setEventMessage", data.error_message);
      } else if (data.success_message) {
        this.commit("setEventMessage", data.success_message);
        if (data.data.current_page) {
          state.dataLists9 = [];
          state.pagination9 = [];
          this.commit("setDataLists9", data.data.data);
          if (data.data.current_page) {
            this.commit("setPagination9", {
              current_page: data.data.current_page,
              first_page_url: data.data.first_page_url,
              last_page_url: data.data.last_page_url,
              prev_page_url: data.data.prev_page_url,
              next_page_url: data.data.next_page_url,
              path: data.data.path,
              last_page: data.data.last_page,
            });
          }
        } else if (data.data) {
          state.dataLists9 = [];
          state.pagination9 = [];
          this.commit("setDataLists9", data.data);
        }
      }
    },
    destroySession(state) {
      this.commit("disableProcessing");
      localStorage.removeItem("token");
      localStorage.removeItem("authData");
      localStorage.removeItem("permissions");
      localStorage.removeItem("sidebar");
      state.token = "";
      state.authData = "";
      location.reload();
    },
  },
  actions: {
    logOut({ commit, state }) {
      if (confirm("Want to clear all the session?")) {
        axios
          .get(state.api_root + "api/logout", {
            headers: { Authorization: `Bearer ${state.token}` },
          })
          .then((res) => {
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
        this.commit("destroySession", state);
      }
      return false;
    },
    modalOpen({ commit, state }, id) {
      state.modalId = id;
      state.modalState = true;
    },
    modalWithMode({ commit, state }, payload) {
      state.modalId = payload.id;
      state.modalMode = payload.mode;
      state.modalState = true;
    },
    modalClose({ commit, state }) {
      state.modalId = "";
      state.modalMode = "";
      state.modalState = false;
    },
    clearRemoveList({ commit, state }) {
      state.removeList = [];
    },
    toggleSidebar({ commit, state }) {
      state.toggleSidebar = state.toggleSidebar == true ? false : true;
    },
    clearPagination({ commit, state }) {
      state.pagination = [];
    },
    enableMessage({ commit, state }) {
      state.messageState = true;
    },
    disableMessage({ commit, state }) {
      state.messageState = false;
    },
    destroyEvent({ commit, state }) {
      state.processing = false;
      state.currentComponent = "";
      state.eventMessage = "";
      state.messageState = false;
      state.apiUrl = "";
      state.dataLists = [];
      state.dataLists1 = [];
      state.dataLists2 = [];
      state.dataLists3 = [];
      state.pagination = [];
      state.pagination1 = [];
      state.pagination2 = [];
      state.pagination3 = [];
      state.dataId = "";
      state.modalMode = "";
      state.dataId1 = "";
      state.dataId2 = "";
      state.dataId3 = "";
      state.removeList = [];
      state.modalId = "";
      state.modalState = false;
    },
  },
};
