const module ={
    namespaced : true,
    state:{
        bankDatas:{},
        bankElements:{},
    },
    getters:{
        bankDatas(state){
            return state.bankDatas;
        },
        bankElements(state){
            return state.bankElements;
        },
    },
    mutations:{
        setBankDatas(state,payload){
            state.bankDatas = payload;
        },
        setBankElements(state,payload){
            state.bankElements = payload;
        }
    }
}
export default module;