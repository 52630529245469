const module = {
    namespaced: true,
    state: {
        barcodeLists: {},
        barcodeBranchs: {},
        barcodeItems:{},
        barcodePurchases:{},
    },
    getters: {
        barcodeLists(state) {
            return state.barcodeLists;
        },
        barcodeBranchs(state) {
            return state.barcodeBranchs;
        },
        barcodeItems(state){
            return state.barcodeItems;
        },
        barcodePurchases(state){
            return state.barcodePurchases;
        }
    },
    mutations: {
        setBarcodeLists(state, payload) {
            state.barcodeLists = payload;
        },
        setBarcodeBranchs(state, payload) {
            state.barcodeBranchs = payload;
        },
        setBarcodeItems(state, payload) {
            state.barcodeItems = payload;
        },
        setBarcodePurchases(state, payload) {
            state.barcodePurchases = payload;
        },
    }
}
export default module;