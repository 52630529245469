const module ={
    namespaced : true,
    state:{
        branchDatas:{},
        branchDetail:{},
        branchElements:{},
    },
    getters:{
        branchDatas(state){
            return state.branchDatas;
        },
        branchElements(state){
            return state.branchElements;
        },
        branchDetail(state){
            return state.branchDetail;
        }
    },
    mutations:{
        setBranchDatas(state,payload){
            state.branchDatas = payload;
        },
        setBranchElements(state,payload){
            state.branchElements = payload;
        },
        setBranchDetail(state,payload){
            state.branchDetail = payload;
        }
    }
}
export default module;