const module ={
    namespaced : true,
    state:{

        profitLossData:{},
              
    },
    getters:{
        profitLossData(state){
            return state.profitLossData;
        },
        
    },
    mutations:{
       setProfitLossData(state,payload){
            state.profitLossData = payload;
        }
    }
}
export default module;