const module = {
    namespaced: true,
    state: {
        expiryWiseDatas: {},
        expiryWiseAttributes: {},
       
    },
    getters: {
        expiryWiseDatas(state) {
            return state.expiryWiseDatas;
        },
        expiryWiseAttributes(state) {
            return state.expiryWiseAttributes;
        },
        

    },
    mutations: {
        setExpiryWiseDatas(state, payload) {
            state.expiryWiseDatas = payload;
        },
        setExpiryWiseAttributes(state, payload) {
            state.expiryWiseAttributes = payload;
        },
       
    }
}
export default module;