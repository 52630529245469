const module ={
    namespaced : true,
    state:{
        itemUnique :true,
        itemLists:{},
        itemElements:{},
        itemSettings:{},
    },
    getters:{
        itemUnique(state){
            return state.itemId;
        },
        itemLists(state){
            return state.itemLists;
        },
        itemElements(state){
            return state.itemElements;
        },
        itemSettings(state){
            return state.itemSettings;
        }
    },
    mutations:{
        setItemLists(state,payload){
            state.itemLists = payload;
        },
        setItemElements(state,payload){
            state.itemElements = payload;
        },
        setItemSettings(state,payload){
            state.itemSettings = payload;
        },
        setItemUnique(state,payload){
            state.itemUnique = payload;
        }
    },
}
export default module;