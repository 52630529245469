const module ={
    namespaced : true,
    state:{
        brandLists:{},
    },
    getters:{
        brandLists(state){
            return state.brandLists;
        },
    },
    mutations:{
        setBrandLists(state,payload){
            state.brandLists = payload;
        },
    },
}
export default module;