<template>
  <span>
    <router-link
      to="/order-placement"
      :style="{ cursor: 'pointer' }"
      class="btn text-uppercase border-radius-0 ml-3"
      :class="$route.path=='/contact-us'?'btn-outline-primary':'btn-white'"
      tag="a"
      v-if="token"
    >Get started</router-link>
    <button
      type="button"
      @click="setComponent"
      class="btn text-uppercase border-radius-0 ml-3"
      :class="$route.path=='/contact-us'?'btn-outline-primary':'btn-white'"
      data-toggle="modal"
      data-target="#loginRegisterModal"
      v-else
    >Get started</button>
  </span>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    setComponent() {
      this.$store.commit("setCurrentComponent", "order-placement");
    },
  },
  computed: {
    ...mapGetters(["token"]),
  },
};
</script>