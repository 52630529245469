const module = {
    namespaced: true,
    state: {
        billWiseDatas: {},
        billWiseAttributes: {},
        branchWiseDatas: {},
        branchWiseAttributes: {},
        dayWiseAttributes: {},
        dayWiseDatas: {},
        monthWiseDatas: {},
        monthWiseAttributes: {},
        supplierWiseDatas: {},
        supplierWiseAttributes: {}
    },
    getters: {
        billWiseDatas(state) {
            return state.billWiseDatas;
        },
        billWiseAttributes(state) {
            return state.billWiseAttributes;
        },
        branchWiseAttributes(state) {
            return state.branchWiseAttributes;
        },
        branchWiseDatas(state) {
            return state.branchWiseDatas;
        },
        dayWiseAttributes(state) {
            return state.dayWiseAttributes;
        },
        dayWiseDatas(state) {
            return state.dayWiseDatas;
        },
        monthWiseDatas(state) {
            return state.monthWiseDatas;
        },
        monthWiseAttributes(state) {
            return state.monthWiseAttributes;
        },
        supplierWiseDatas(state) {
            return state.supplierWiseDatas;
        },
        supplierWiseAttributes(state) {
            return state.supplierWiseAttributes;
        }

    },
    mutations: {
        setBillWiseDatas(state, payload) {
            state.billWiseDatas = payload;
        },
        setBillWiseAttributes(state, payload) {
            state.billWiseAttributes = payload;
        },
        setBranchWiseDatas(state, payload) {
            state.branchWiseDatas = payload;
        },
        setBranchWiseAttributes(state, payload) {
            state.branchWiseAttributes = payload;
        },
        setDayWiseAttributes(state, payload) {
            state.dayWiseAttributes = payload;
        },
        setDayWiseDatas(state, payload) {
            state.dayWiseDatas = payload;
        },
        setMonthWiseDatas(state, payload) {
            state.monthWiseDatas = payload;
        },
        setMonthWiseAttributes(state, payload) {
            state.monthWiseAttributes = payload;
        },
        setSupplierWiseDatas(state, payload) {
            state.supplierWiseDatas = payload;
        },
        setSupplierWiseAttributes(state, payload) {
            state.supplierWiseAttributes = payload;
        }
    }
}
export default module;