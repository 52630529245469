<template>
  <div>
    <div class="top-nav">
      <div class="px-5">
        <div class="row">
          <div class="col-md-7">
            <ul>
              <li>
                <a href="javascript:;">
                  <i class="fa fa-location-arrow"></i> Tinkune Kathmandu Nepal
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <i class="fa fa-envelope-open"></i> info@softechpark.com
                </a>
              </li>
              <li>
                <a href="javascript:;">
                  <i class="fas fa-phone-volume"></i> (+977) 01 - 4111992
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-5 text-right">
            <a href="javascript:;">
              <img src="assets/icon/24x7.png" class="img-fluid" />
            </a>
            <router-link
              v-if="token"
              to="/home"
              active-class="active"
              exact
              :style="{ cursor: 'pointer' }"
              class="login-button ml-3"
              tag="button"
            >Dashboard</router-link>
            <button
              v-else
              type="button"
              class="login-button ml-3"
              @click="setComponent"
              data-toggle="modal"
              data-target="#loginRegisterModal"
            >Login</button>
          </div>
        </div>
      </div>
    </div>
    <div class="px-5">
      <nav class="navbar navbar-expand-lg">
        <router-link
          to="/"
          active-class="active"
          exact
          :style="{ cursor: 'pointer' }"
          class="navbar-brand"
          tag="a"
        >
          <img src="assets/icon/softwarepark-logo.png" alt="Logo" />
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
          <span class="toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
          <ul class="navbar-nav ml-2">
            <li class="nav-item" :class="$route.path=='/'?'active':''">
              <router-link
                to="/"
                active-class="active"
                exact
                :style="{ cursor: 'pointer' }"
                class="nav-link"
                tag="a"
              >Home</router-link>
            </li>
            <li class="nav-item" :class="$route.path=='/plan-price'?'active':''">
              <router-link
                to="/plan-price"
                active-class="active"
                exact
                :style="{ cursor: 'pointer' }"
                class="nav-link"
                tag="a"
              >plans & pricing</router-link>
            </li>
            <li class="nav-item" :class="$route.path=='/feature'?'active':''">
              <router-link
                to="/feature"
                active-class="active"
                exact
                :style="{ cursor: 'pointer' }"
                class="nav-link"
                tag="a"
              >feature</router-link>
            </li>
            <li class="nav-item" :class="$route.path=='/earn'?'active':''">
              <router-link
                to="/earn"
                active-class="active"
                exact
                :style="{ cursor: 'pointer' }"
                class="nav-link"
                tag="a"
              >earn</router-link>
            </li>
            <li class="nav-item" :class="$route.path=='/about-us'?'active':''">
              <router-link
                to="/about-us"
                active-class="active"
                exact
                :style="{ cursor: 'pointer' }"
                class="nav-link"
                tag="a"
              >about</router-link>
            </li>
            <li class="nav-item" :class="$route.path=='/blog'?'active':''">
              <router-link
                to="/blog"
                active-class="active"
                exact
                :style="{ cursor: 'pointer' }"
                class="nav-link"
                tag="a"
              >blog</router-link>
            </li>
            <li class="nav-item" :class="$route.path=='/contact-us'?'active':''">
              <router-link
                to="/contact-us"
                active-class="active"
                exact
                :style="{ cursor: 'pointer' }"
                class="nav-link"
                tag="a"
              >Contact</router-link>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <li class="navbar-btn d-none d-sm-inline-block">
              <a class="main-btn sticky_acc" target="blank" href="/login">
                WORKSPACE
                <i class="fas fa-long-arrow-alt-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <!-- login / register modal -->
    <div
      class="modal fade"
      id="loginRegisterModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="loginRegisterModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="row">
            <div class="col-md-6 mr-0 pr-0" style="background: #1451C0;">
              <img
                src="assets/icon/login-side.png"
                alt
                class="img-fluid"
                style="height: 100%;"
                id="loginRegImg"
              />
            </div>
            <div class="col-md-5 mr-0 pr-0 login-register">
              <div class="row">
                <div class="col-md-12 py-4">
                  <ul class="nav nav-tabs float-right" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="loginForm-tab"
                        data-toggle="tab"
                        href="#loginForm"
                        role="tab"
                        aria-controls="loginForm"
                        aria-selected="true"
                      >Log In</a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="registerForm-tab"
                        data-toggle="tab"
                        href="#registerForm"
                        role="tab"
                        aria-controls="registerForm"
                        aria-selected="false"
                      >Register</a>
                    </li>
                  </ul>
                </div>
                <div class="col-md-12">
                  <div class="tab-content" id="myTabContent">
                    <form
                      method="post"
                      @submit.prevent="Login"
                      class="tab-pane fade show active login"
                      id="loginForm"
                      role="tabpanel"
                      aria-labelledby="loginForm-tab"
                    >
                      <h3>Log In</h3>
                      <div class="form-group" :class="{ invalid: $v.loginData.email.$error }">
                        <label for="email">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          name="email"
                          placeholder="info@softechpark.com.uk"
                          @blur="$v.loginData.email.$touch()"
                          v-model.trim="loginData.email"
                          required
                        />
                        <div v-if="$v.loginData.email.$error">
                          <p
                            class="error_level"
                            v-if="!$v.loginData.email.required"
                          >Email field is required.</p>
                          <p
                            class="error_level"
                            v-if="!$v.loginData.email.minLength"
                          >At least 6 charecter email.</p>
                          <p
                            class="error_level"
                            v-if="!$v.loginData.email.email"
                          >Please enter valid email.</p>
                        </div>
                      </div>
                      <div class="form-group" :class="{ invalid: $v.loginData.password.$error }">
                        <label for="password">Password</label>
                        <input
                          type="password"
                          class="form-control"
                          name="password"
                          placeholder="password"
                          @blur="$v.loginData.password.$touch()"
                          v-model.trim="loginData.password"
                          required
                        />
                        <div v-if="$v.loginData.password.$error">
                          <p
                            class="error_level"
                            v-if="!$v.loginData.password.required"
                          >Password field is required.</p>
                          <p
                            class="error_level"
                            v-if="!$v.loginData.password.minLength"
                          >At least 6 charecter password.</p>
                        </div>
                      </div>
                      <button
                        class="btn btn-primary border-radius-0 my-3"
                        style="width: 100%;"
                        type="submit"
                      >SIGN IN</button>
                      <div>
                        <a href="javascript:;">Forgot Password ?</a>
                        <a href="javascript:;" class="float-right">Not a Member yet ?</a>
                      </div>
                      <div class="text-justify">
                        <p class="error_level">{{eventMessage}}</p>
                      </div>
                    </form>
                    <form
                      method="post"
                      @submit.prevent="signUp"
                      class="tab-pane fade register"
                      id="registerForm"
                      role="tabpanel"
                      aria-labelledby="registerForm-tab"
                    >
                      <h3>Register</h3>
                      <div class="form-group" :class="{ invalid: $v.registerData.name.$error }">
                        <label for="full_name">Full Name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="full_name"
                          placeholder="Softechpark"
                          @blur="$v.registerData.name.$touch()"
                          v-model.trim="registerData.name"
                          required
                        />
                        <div v-if="$v.registerData.name.$error">
                          <p
                            class="error_level"
                            v-if="!$v.registerData.name.required"
                          >Name field is required.</p>
                          <p
                            class="error_level"
                            v-if="!$v.registerData.name.minLength"
                          >At least 2 character name.</p>
                        </div>
                      </div>
                      <div class="form-group" :class="{ invalid: $v.registerData.email.$error }">
                        <label for="email">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="info@softechpark.com.uk"
                          @blur="$v.registerData.email.$touch()"
                          v-model.trim="registerData.email"
                          required
                        />
                        <div v-if="$v.registerData.email.$error">
                          <p
                            class="error_level"
                            v-if="!$v.registerData.email.required"
                          >Email field is required.</p>
                          <p
                            class="error_level"
                            v-if="!$v.registerData.email.minLength"
                          >At least 6 character email.</p>
                          <p
                            class="error_level"
                            v-if="!$v.registerData.email.email"
                          >Please enter valid email.</p>
                        </div>
                      </div>
                      <div class="form-group" :class="{ invalid: $v.registerData.phone.$error }">
                        <label for="contact">Contact Number</label>
                        <input
                          type="number"
                          class="form-control"
                          id="contact"
                          placeholder="9840680875"
                          @blur="$v.registerData.phone.$touch()"
                          v-model.trim="registerData.phone"
                          required
                        />
                        <div v-if="$v.registerData.phone.$error">
                          <p
                            class="error_level"
                            v-if="!$v.registerData.phone.required"
                          >Phone field is required.</p>
                          <p
                            class="error_level"
                            v-if="!$v.registerData.phone.minLength"
                          >At least 7 character phone.</p>
                        </div>
                      </div>
                      <div class="form-group" :class="{ invalid: $v.registerData.password.$error }">
                        <label for="password">Password</label>
                        <input
                          type="password"
                          class="form-control"
                          id="password"
                          placeholder="password"
                          @blur="$v.registerData.password.$touch()"
                          v-model.trim="registerData.password"
                          required
                        />
                        <div v-if="$v.registerData.password.$error">
                          <p
                            class="error_level"
                            v-if="!$v.registerData.password.required"
                          >Password field is required.</p>
                          <p
                            class="error_level"
                            v-if="!$v.registerData.password.minLength"
                          >At least 6 character password.</p>
                        </div>
                      </div>
                      <div
                        class="form-group"
                        :class="{ invalid: $v.registerData.c_password.$error }"
                      >
                        <label for="c_password">Re-Type Password</label>
                        <input
                          type="password"
                          class="form-control"
                          id="c_password"
                          placeholder="password"
                          @blur="$v.registerData.c_password.$touch()"
                          v-model.trim="registerData.c_password"
                          required
                        />
                        <div v-if="$v.registerData.c_password.$error">
                          <p
                            class="error_level"
                            v-if="!$v.registerData.c_password.required"
                          >Confirm Password field is required.</p>
                          <p
                            class="error_level"
                            v-if="!$v.registerData.c_password.minLength"
                          >At least 6 character confirm password.</p>
                        </div>
                        <p class="error_level">{{error}}</p>
                      </div>
                      <div class="form-group">
                        <label for="referral_code">Referral Code</label>
                        <input
                          type="text"
                          class="form-control"
                          id="referral_code"
                          name="refral_code"
                          placeholder="123456HD"
                        />
                      </div>
                      <button
                        class="btn btn-primary border-radius-0 my-3"
                        type="submit"
                        style="width: 100%;"
                      >REGISTER</button>
                      <div class="text-justify">
                        <p
                          :class="eventMessage.indexOf('success')>=0?'success_level':'error_level'"
                        >{{eventMessage}}</p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters([
      "token",
      "apiRoot",
      "processing",
      "eventMessage",
      "currentComponent",
    ]),
  },
  data() {
    return {
      error: "",
      loginData: {
        email: "",
        password: "",
      },
      registerData: {
        name: "",
        email: "",
        phone: "",
        password: "",
        c_password: "",
        referral_code: "",
      },
    };
  },
  validations: {
    loginData: {
      email: {
        required,
        email,
        minLength: minLength(6),
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
    registerData: {
      name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(255),
      },
      email: {
        required,
        email,
        minLength: minLength(6),
      },
      phone: {
        required,
        numeric,
        minLength: minLength(7),
      },
      password: {
        required,
        minLength: minLength(6),
      },
      c_password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  methods: {
    setComponent() {
      this.$store.commit("setCurrentComponent", "");
    },
    signUp() {
      if (!this.$v.registerData.$invalid) {
        this.$store.commit("setApiUrl", "api/user/store");
        this.$store.commit("addData", this.registerData);
      } else {
        this.$store.commit("setEventMessage", `Please fill required fields.`);
      }
    },
    Login() {
      if (!this.$v.$invalid) {
        this.$store.commit("enableProcessing");
        this.$http
          .post(`${this.apiRoot}api/login`, this.loginData)
          .then((response) => {
            this.$store.commit("disableProcessing");
            if (response.body.error_message) {
              this.$store.commit(
                "setEventMessage",
                response.body.error_message
              );
            } else {
              this.$store.commit(
                "setEventMessage",
                response.data.success_message
              );
              this.checkAuthUser(response.data);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$store.commit("disableProcessing");
            this.$store.commit("setEventMessage", err.body.error);
          });
      } else {
        this.$store.commit(
          "setEventMessage",
          `Something went wrong. please try again later.`
        );
      }
    },
    checkAuthUser(auth) {
      if (!auth.data.email_verified_at) {
        this.$store.commit(
          "setEventMessage",
          "This credential is not verified yet. Please contact to administer."
        );
      } else if (auth.data.deleted_at) {
        this.$store.commit(
          "setEventMessage",
          "This credential is temporary blocked. Please contact to administer."
        );
      } else if (auth.data.status == "0") {
        this.$store.commit(
          "setEventMessage",
          "This credential is not approved yet. Please contact to administer."
        );
      } else if (auth.data.status == "2") {
        this.$store.commit(
          "setEventMessage",
          "This credential is deactivated. Please contact to administer."
        );
      } else {
        this.setAuthData(auth);
      }
    },
    setAuthData(auth) {
      localStorage.setItem("authData", JSON.stringify(auth.data));
      // console.log(JSON.parse(localStorage.getItem("authData")));
      localStorage.setItem("token", auth.token);
      let permissions = [];
      auth.data.roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          permissions.push(permission.permission_key);
        });
      });
      const unique = (value, index, self) => {
        return self.indexOf(value) === index;
      };
      localStorage.setItem(
        "permissions",
        JSON.stringify(permissions.filter(unique))
      );
      this.commitAuthData(auth);
    },
    commitAuthData(auth) {
      this.$store.commit("setToken", auth.token);
      this.$store.commit("setAuthData", auth.data);
      if (this.currentComponent) {
        this.$router.push(`/${this.currentComponent}`);
      } else {
        this.$router.push("/home");
      }
      location.reload();
    },
  },
  watch: {
    "registerData.c_password"(value) {
      if (this.registerData.password != value) {
        this.error = "Password and confirm password doesn't match";
      } else {
        this.error = "";
      }
    },
    "registerData.password"(value) {
      if (
        this.registerData.c_password != value &&
        this.registerData.c_password.length > 0
      ) {
        this.error = "Password and confirm password doesn't match";
      } else {
        this.error = "";
      }
    },
  },
  destroyed() {
    this.$store.commit("setEventMessage", "");
    this.$store.commit("setCurrentComponent", "");
  },
};
</script>

<style scoped>
.login-register .nav-tabs {
  border: none;
}
.login-register .nav-link {
  padding: 0rem 1rem !important;
}
.login-register h3 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 1rem;
}
.login-register .register label {
  margin-bottom: 0rem;
}
.login-register .register .form-group {
  margin-bottom: 0.2rem;
}
.login-register .form-control {
  border: none;
  border-bottom: 1px solid #ced4da;
}
</style>