const module = {
    namespaced: true,
    state: {
        minStockWiseDatas: {},
       
    },
    getters: {
        minStockWiseDatas(state) {
            return state.minStockWiseDatas;
        },
       
        

    },
    mutations: {
        setMinStockWiseDatas(state, payload) {
            state.minStockWiseDatas = payload;
        },
       
       
    }
}
export default module;