const module ={
    namespaced : true,
    state:{
        unitTypeLists:{},
    },
    getters:{
        unitTypeLists(state){
            return state.unitTypeLists;
        },
    },
    mutations:{
        setUnitTypeLists(state,payload){
            state.unitTypeLists = payload;
        },
    },
}
export default module;