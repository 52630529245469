const module ={
    namespaced : true,
    state:{

        balanceSheetData:{},
              
    },
    getters:{
        balanceSheetData(state){
            return state.balanceSheetData;
        },
        
    },
    mutations:{
       setBalanceSheetData(state,payload){
            state.balanceSheetData = payload;
        }
    }
}
export default module;