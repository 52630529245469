const module ={
    namespaced:true,
    state:{
        itmWithQtyWise:{},
        itmWithQtyWiseAttributes:{},
        itmWithValueWise:{},
        itmWithValueWiseAttributes:{},
        itmWithStateWise:{},
        itmWithStateWiseAttributes:{},
        dayWithQtyWise:{},
        dayWithQtyWiseAttributes:{},
        dayWithValueWise:{},
        dayWithValueWiseAttributes:{},
        monthWithQtyWise:{},
        monthWithQtyWiseAttributes:{},
        monthWithValueWise:{},
        monthWithValueWiseAttributes:{},
        branchWithQtyWise:{},
        branchWithQtyWiseAttributes:{},
        branchWithValueWise:{},
        branchWithValueWiseAttributes:{},
    },
    getters:{
        itmWithQtyWise(state) {
            return state.itmWithQtyWise;
        },
        itmWithQtyWiseAttributes(state) {
            return state.itmWithQtyWiseAttributes;
        },
         itmWithValueWise(state) {
            return state.itmWithValueWise;
        },
        itmWithValueWiseAttributes(state) {
            return state.itmWithValueWiseAttributes;
        },
    },
    mutations:{
        setItmWithQtyWise(state, payload) {
            state.itmWithQtyWise = payload;
        },
        setItmWithQtyWiseAttributes(state, payload) {
            state.itmWithQtyWiseAttributes = payload;
        },
         setItmWithValueWise(state, payload) {
            state.itmWithValueWise = payload;
        },
        setItmWithValueWiseAttributes(state, payload) {
            state.itmWithValueWiseAttributes = payload;
        },
    }

}

export default module;