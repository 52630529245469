const module = {
    namespaced: true,
    state: {
        daybookData: {},

    },
    getters: {
        daybookData(state) {
            return state.daybookData;
        },
    },
    mutations: {
        setDaybookData(state, payload) {
            state.daybookData = payload;
        },
        
    },
    actions: {
    }
}
export default module;
