const module ={
    namespaced : true,
    state:{
       balanceDatas:{},
       balanceElements:{},
    },
    getters:{
       balanceDatas(state){
           return state.balanceDatas;
       },
       balanceElements(state){
           return state.balanceElements;
       }
    },
    mutations:{
        setBalanceDatas(state,payload){
            state.balanceDatas = payload;
        },
        setBalanceElements(state,payload){
            state.balanceElements = payload;
        },
    }
}
export default module;