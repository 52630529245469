const module ={
    namespaced : true,
    state:{
       taxDatas:{},
       taxElements:{},
    },
    getters:{
       taxDatas(state){
           return state.taxDatas;
       },
       taxElements(state){
           return state.taxElements;
       }
    },
    mutations:{
        setTaxDatas(state,payload){
            state.taxDatas = payload;
        },
        setTaxElements(state,payload){
            state.taxElements = payload;
        },
    }
}
export default module;