const module = {
    namespaced: true,
    state: {
        salesItem: {},
        itemsByBranch: {
            items: [],
            branches: [],
            orderId: '',
            paymentMethods: []
        },
        purhcaseItemDetails: [],
        branchId: '',
        itemGroups: [],
        dataSets: [],
        slipSettings: [],
        itemSearchLoading: false,
        canceledUser: '',

    },
    getters: {
        purhcaseItemDetails(state) {
            return state.purhcaseItemDetails;
        },
        salesItem(state) {
            return state.salesItem;
        },
        itemsByBranch(state) {
            return state.itemsByBranch;
        },
        branchId(state) {
            return state.branchId;
        },
        itemGroups(state) {
            return state.itemGroups;
        },
        dataSets(state) {
            return state.dataSets;
        },
        slipSettings(state) {
            return state.slipSettings;
        },
        itemSearchLoading(state) {
            return state.itemSearchLoading;
        },
        canceledUser(state) {
            return state.canceledUser;
        }

    },
    mutations: {
        setPurhcaseItemDetails(state, payload) {
            state.purhcaseItemDetails = payload;
        },
        setSalesItem(state, payload) {
            state.salesItem = payload;
        },
        setItemsByBranch(state, payload) {
            state.itemsByBranch = payload;
        },
        setBranchId(state, id) {
            state.branchId = id;
        },
        setItemGroups(state, payload) {
            state.itemGroups = payload;
        },
        setDataSets(state, payload) {
            state.dataSets = payload;
        },
        setSlipSettings(state, payload) {
            state.slipSettings = payload;
        },
        setItemSearchLoading(state, boolean) {
            state.itemSearchLoading = boolean;
        },
        setCanceledUser(state, payload) {
            state.canceledUser = payload;
        },
        setItemsByBranchItems(state, payload) {
            state.itemsByBranch.items = payload;
        },
        setPushItemsByBranchItems(state, payload) {
            state.itemsByBranch.items.unshift(payload);
        },
        setItemsByBranchSalesId(state, id) {
            state.itemsByBranch.salesId = id;
        }
    },
    actions: {
    }
}
export default module;