const module = {
    namespaced: true,
    state: {
        salesItem: {},
        itemsByBranch: {
            items: [],
            branches: [],
        },
        leads: [],
        purhcaseItemDetails: [],
        userType: '',
        branchId: '',
        itemGroups: [],
        dataSets: [],
        slipSettings: [],
        itemSearchLoading: false,
        leadSearchLoading: false,
        selectedUser: {},
        canceledUser: '',

    },
    getters: {
        purhcaseItemDetails(state) {
            return state.purhcaseItemDetails;
        },
        salesItem(state) {
            return state.salesItem;
        },
        itemsByBranch(state) {
            return state.itemsByBranch;
        },
        branchId(state) {
            return state.branchId;
        },
        itemGroups(state) {
            return state.itemGroups;
        },
        dataSets(state) {
            return state.dataSets;
        },
        slipSettings(state) {
            return state.slipSettings;
        },
        itemSearchLoading(state) {
            return state.itemSearchLoading;
        },
        leadSearchLoading(state) {
            return state.leadSearchLoading;
        },
        canceledUser(state) {
            return state.canceledUser;
        },
        leads(state) {
            return state.leads;
        },
        userType(state) {
            return state.userType;
        },
        selectedUser(state) {
            return state.selectedUser;
        }
    },
    mutations: {
        setPurhcaseItemDetails(state, payload) {
            state.purhcaseItemDetails = payload;
        },
        setSalesItem(state, payload) {
            state.salesItem = payload;
        },
        setItemsByBranch(state, payload) {
            state.itemsByBranch = payload;
        },
        setBranchId(state, id) {
            state.branchId = id;
        },
        setItemGroups(state, payload) {
            state.itemGroups = payload;
        },
        setDataSets(state, payload) {
            state.dataSets = payload;
        },
        setSlipSettings(state, payload) {
            state.slipSettings = payload;
        },
        setItemSearchLoading(state, boolean) {
            state.itemSearchLoading = boolean;
        },
        setCanceledUser(state, payload) {
            state.canceledUser = payload;
        },
        setLeads(state, payload) {
            state.leads = payload;
        },
        setUserType(state, payload) {
            state.userType = payload;
        },
        setLeadSearchLoading(state, boolean) {
            state.leadSearchLoading = boolean;
        },
        setSelectedUser(state, payload) {
            state.selectedUser = payload;
        },
    },
    actions: {
    }
}
export default module;