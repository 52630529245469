const module = {
    namespaced: true,
    state: {
        dataSets: [],
        itemSearchLoading: false

    },
    getters: {

        dataSets(state) {
            return state.dataSets;
        },
        itemSearchLoading(state) {
            return state.itemSearchLoading;
        }

    },
    mutations: {

        setDataSets(state, payload) {
            state.dataSets = payload;
        },
        setItemSearchLoading(state, boolean) {
            state.itemSearchLoading = boolean;
        }
    },
    actions: {
    }
}
export default module;