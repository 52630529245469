export default {
    data() {
      return {
       user_permissions : JSON.parse(localStorage.getItem('permissions')),
       permission:'',
      };
    },
    methods: {
        checkSinglePermission:function(permission){
            if(this.user_permissions.includes(permission)){
                return true;
            }
            else{
                return false;
            }
        },
        checkAllIncludesPermission:function(permission){
            if(this.user_permissions.every(permission)){
                return true;
            }else{
                return false;
            }
        },
        checkIfAnyPermission:function(permission){
            var result = this.user_permissions.filter(function(item){ return permission.indexOf(item) > -1});   
            return (result.length > 0);
        }
    },
  };  