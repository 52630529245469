/*eslint-disable*/
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const authGuard = {
  beforeEnter: (to, from, next) => {
    //check if user is logged in and has token
    if (localStorage.getItem("token")) {
      let permissions = JSON.parse(localStorage.getItem('permissions'));
      //once user is logged in ,check if the desire route need to pass through permissions validation
      if (to.matched.some(record => record.meta.permissions)) {
        //looping through the permissions given in a route to check whethere 
        // the user has permission to naviagte the desired page
        const matchedPermissions = to.meta.permissions.filter(value => permissions.includes(value));
        if (matchedPermissions.length > 0) {
          next();
        } else {
          //if no permission then redirect user to access denied page
          next({ name: 'access-denied' });
        }
        next();
      }
      next();
    } else {
      next({ name: 'login-page' });
    }

  },
};

const index = [
  {
    path: "/test/feature/get",
    component: () => import("../tests/feature/get"),
    meta: { title: "GET REQUEST", key: "Backend" },
  },
  {
    path: "/test/feature/get",
    component: () => import("../tests/feature/get"),
    meta: { title: "GET REQUEST", key: "Backend" },
  },
  {
    path: "/test/feature/delete",
    component: () => import("../tests/feature/delete"),
    meta: { title: "DELETE REQUEST", key: "Backend" },
  },
  {
    path: "/test/feature/post",
    component: () => import("../tests/feature/post"),
    meta: { title: "POST REQUEST", key: "Backend" },
  },
  {
    path: "/test/feature/update",
    component: () => import("../tests/feature/update"),
    meta: { title: "UPDATE REQUEST", key: "Backend" },
  },
  {
    path: "/test/feature/permission",
    component: () => import("../tests/feature/permission"),
    meta: { title: "Permission Test", key: "Backend" },
  },
  {
    path: "/test/unit/design",
    component: () => import("../tests/unit/design"),
    meta: { title: "Unit Test | Desing", key: "Backend" },
  },
  {
    path: "/test/unit/modal",
    component: () => import("../tests/unit/modal"),
    meta: { title: "Unit Test | Modal", key: "Backend" },
  },
  {
    path: "/test/unit/purchase",
    component: () => import("../tests/unit/purchase"),
    meta: { title: "Unit Test | Purchase", key: "Backend" },
  },
  {
    path: "/test/unit/newpurchase",
    component: () => import("../tests/unit/NewPurchase"),
    meta: { title: "Unit Test | New Purchase", key: "Backend" },
  },
  {
    path: "/test/unit/alert",
    component: () => import("../tests/unit/alert"),
    meta: { title: "Unit Test | Alert", key: "Backend" },
  },
  {
    path: "*",
    component: () => import("../shared/404Error"),
    meta: { title: "E - Billing | 404", key: 'YearTransfer' },
  },
  {
    path: "/",
    component: () => import("../resources/auth/login"),
    meta: { title: "Login", key: "Login" },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("token")) {
        next("/home");
      } else {
        next();
      }
    },
  },

  {
    path: "/Plan-price",
    component: () => import("../resources/website/plan-price"),
    meta: { title: "Plan & Pricing", key: "Website" },
  },
  {
    path: "/feature",
    component: () => import("../resources/website/feature"),
    meta: { title: "Feature", key: "Website" },
  },
  {
    path: "/earn",
    component: () => import("../resources/website/earn"),
    meta: { title: "Earn", key: "Website" },
  },
  {
    path: "/about-us",
    component: () => import("../resources/website/about-us"),
    meta: { title: "About Us", key: "Website" },
  },
  {
    path: "/blog",
    component: () => import("../resources/website/blog"),
    meta: { title: "Blog", key: "Website" },
  },
  {
    path: "/contact-us",
    component: () => import("../resources/website/contact-us"),
    meta: { title: "Contact Us", key: "Website" },
  },
  {
    path: "/free-trial",
    component: () => import("../resources/website/free-trial"),
    meta: { title: "Free Trial", key: "Website" },
    ...authGuard,
  },
  {
    path: "/order-placement",
    component: () => import("../resources/website/order-placement"),
    meta: { title: "Order Placement", key: "Website" },
    ...authGuard,
  },
  {
    path: "/agent",
    component: () => import("../resources/website/agent"),
    meta: { title: "Become An Agent", key: "Website" },
    ...authGuard,
  },
  {
    path: "/affiliate",
    component: () => import("../resources/website/agent"),
    meta: { title: "Become An Affiliate", key: "Website" },
    ...authGuard,
  },
  {
    path: "/partner",
    component: () => import("../resources/website/become-partner"),
    meta: { title: "Become A Partner", key: "Website" },
    ...authGuard,
  },
  {
    path: "/access-denied",
    name: "access-denied",
    component: () => import("../shared/AccessDenied"),
    meta: { title: "Access Denied", key: "backend" },
    ...authGuard,
  },
  {
    path: "/login",
    name: "login-page",
    component: () => import("../resources/auth/login"),
    meta: { title: "Login", key: "Login" },
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("token")) {
        next("/home");
      } else {
        next();
      }
    },
  },
  {
    path: "/recover-new-password/:remember_token",
    component: () => import("../resources/auth/login"),
    meta: { title: "Set New Password", key: "Login" },
  },
  {
    path: "/home",
    component: () => import("../resources/general/dashboard"),
    meta: { title: "Dashboard", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/orders/:state",
    component: () => import("../resources/Order/index"),
    meta: { title: "Orders", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/demos/:state",
    component: () => import("../resources/Demo/index"),
    meta: {
      title: "Demos",
      key: "Backend",
    },
    ...authGuard,
  },
  {
    path: "/bills/:state",
    component: () => import("../resources/Bill/index"),
    meta: { title: "Bills", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/manage/plans",
    component: () => import("../resources/Plan/index"),
    meta: { title: "Plans", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/manage/features",
    component: () => import("../resources/Feature/index"),
    meta: { title: "Features", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/manage/validity",
    component: () => import("../resources/Validity/index"),
    meta: { title: "Validity", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/manage/promo-code",
    component: () => import("../resources/PromoCode/index"),
    meta: { title: "Promo Code", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/manage/cost/additional",
    component: () => import("../resources/AdditionalCost/index"),
    meta: { title: "Additional Cost Code", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/settings/commission",
    component: () => import("../resources/Settings/Commission/index"),
    meta: { title: "Commission Settings", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/website/testimonial",
    component: () => import("../resources/Manage/website/testiminial/index"),
    meta: { title: "Manage Testimonials", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/website/partner",
    component: () => import("../resources/Manage/website/partner/index"),
    meta: { title: "Manage Partners", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/website/contact",
    component: () => import("../resources/Manage/website/contact/index"),
    meta: { title: "Manage Contact Details", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/website/plan-pricing",
    component: () => import("../resources/Manage/website/plan-pricing/index"),
    meta: { title: "Manage plan and pricing details", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/settings/general",
    component: () => import("../resources/Settings/general/index"),
    meta: { title: "General Settings", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/configuration/user",
    name: "user-list",
    component: () => import("../resources/Admin/user/index"),
    meta: { title: "Users Management", key: "Backend", permissions: ['view_user', 'create_user'] },
    ...authGuard,
  },
  {
    path: "/configuration/role",
    name: "list-roles",
    component: () => import("../resources/Admin/role/index"),
    meta: { title: "Role Management", key: "Backend", permissions: ["view_role", 'create_role'] },
    ...authGuard,
  },
  {
    path: "/configuration/new-role",
    name: "new-role",
    component: () => import("../resources/Admin/role/create"),
    meta: { title: "New Role", key: "Backend", permissions: ["create_role"] },
    ...authGuard,
  },
  {
    path: "/configuration/role/:id",
    name: 'edit-role',
    component: () => import("../resources/Admin/role/edit"),
    meta: { title: "New Role", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/configuration/module",
    component: () => import("../resources/Admin/module/index"),
    meta: { title: "Module Management", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/configuration/module/group",
    component: () => import("../resources/Admin/ModuleGroup/index"),
    meta: { title: "Module Group Management", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/configuration/permission",
    component: () => import("../resources/Admin/permission/index"),
    meta: { title: "Permission Management", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/configuration/setting-module",
    component: () =>
      import("../resources/EbillingProduct/Setting/ManageSetting"),
    meta: { title: "Setting Management", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/branch",
    name: "branch-list",
    component: () => import("../resources/Admin/branch/index"),
    meta: { title: "Branch Management", key: "Backend", permissions: ["view_branch", "create_branch"] },
    ...authGuard,
  },
  {
    path: "/stock/new-opening-stock",
    name: "new-opening-stock",
    component: () =>
      import("../resources/EbillingProduct/OpeningStock/Create"),
    meta: { title: "New Opening Stock", key: "Backend", permissions: ["create_opening_stock"] },
    ...authGuard,
  },
  {
    path: "/opening-stock/edit/:id",
    name: "edit-opening-stock",
    component: () =>
      import("../resources/EbillingProduct/OpeningStock/Edit"),
    meta: { title: "Edit Opening Stock", key: "Backend", permissions: ["create_opening_stock"] },
    ...authGuard,
  },
  {
    path: "/purchase/new",
    name: "new-purchase",
    component: () =>
      import("../resources/EbillingProduct/Purchase/NewPurchase"),
    meta: { title: "New Purchase", key: "Backend", permissions: ["create_purchase"] },
    ...authGuard,
  },
  {
    path: "/purchase/order/new",
    name: "new-purchase-order",
    component: () => import("../resources/EbillingProduct/Purchase/NewOrder"),
    meta: { title: "New Purchase Order", key: "Backend", permissions: ["create_purchase_order"] },
    ...authGuard,
  },
  {
    path: "/purchase/order/checkout/:id",
    name: "purchase-order-checkout",
    component: () => import("../resources/EbillingProduct/Purchase/PurchaseOrderCheckout"),
    meta: { title: "Checkout Purchase Order", key: "Backend", permissions: ["checkout_purchase_order"] },
    ...authGuard,
  },
  {
    path: "/supplier",
    name: "supplier_list",
    component: () =>
      import("../resources/EbillingProduct/Supplier/ManageSupplier"),
    meta: { title: "Supplier", key: "Backend", permissions: ["view_supplier"] },
    ...authGuard,
  },
  {
    path: "/item",
    name: "item-list",
    component: () => import("../resources/EbillingProduct/Item/ManageItem"),
    meta: { title: "Item", key: "Backend", permissions: ['view_item', 'create_item'] },
    ...authGuard,
  },
  {
    path: "/tools/item/group",
    name: 'item-group-list',
    component: () => import("../resources/EbillingProduct/ItemGroup/index"),
    meta: { title: "Item Group", key: "Backend", permissions: ['view_item_group', 'create_item_group'] },
    ...authGuard,
  },
  {
    path: "/tools/item/unit",
    name: "unit-type-list",
    component: () => import("../resources/EbillingProduct/UnitType/index"),
    meta: { title: "Unit Type", key: "Backend", permissions: ['view_item_unit', 'create_item_unit'] },
    ...authGuard,
  },
  {
    path: "/customer",
    name: "customer-list",
    component: () =>
      import("../resources/EbillingProduct/Customer/ManageCustomer"),
    meta: { title: "Manage Customer", key: "Backend", permissions: ["view_customer", "create_customer"] },
    ...authGuard,
  },
  {
    path: "/stockdetail",
    component: () =>
      import("../resources/EbillingProduct/Inventory/Stock/index"),
    meta: { title: "Stock Detail", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/inventory/stock-transfer",
    name: "transfer-item-list",
    component: () =>
      import("../resources/EbillingProduct/Inventory/StockTransfer/index"),
    meta: { title: "Stock Transfer", key: "Backend", permissions: ["view_stock_transfer", "create_stock_transfer"] },
    ...authGuard,
  },
  {
    path: "/inventory/transfer/new",
    name: "new-transfer",
    component: () =>
      import("../resources/EbillingProduct/Inventory/StockTransfer/create"),
    meta: { title: "New Stock Transfer", key: "Backend", permissions: ["create_stock_transfer"] },
    ...authGuard,
  },
  {
    path: "/inventory/transfer/edit/:id",
    name: "edit-transfer",
    component: () =>
      import("../resources/EbillingProduct/Inventory/StockTransfer/edit"),
    meta: { title: "Edit Stock Transfer", key: "Backend", permissions: ["edit_stock_transfer"] },
    ...authGuard,
  },
  {
    path: "/inventory/stock-adjustment",
    name: "list-stock-adjustment",
    component: () =>
      import("../resources/EbillingProduct/Inventory/StockAdjustment/index"),
    meta: { title: "Stock Adjustment", key: "Backend", permissions: ["view_stock_adjustment", "create_stock_adjustment"] },
    ...authGuard,
  },
  {
    path: "/inventory/stock-adjustment/new",
    name: "new-stock-adjustment",
    component: () =>
      import("../resources/EbillingProduct/Inventory/StockAdjustment/create"),
    meta: { title: "New Stock Adjustment", key: "Backend", permissions: ["create_stock_adjustment"] },
    ...authGuard,
  },
  {
    path: "/inventory/adjustment/manage/:id",
    name: "edit-stock-adjustment",
    component: () =>
      import("../resources/EbillingProduct/Inventory/StockAdjustment/edit"),
    meta: { title: "Manage Stock Adjustment", key: "Backend", permissions: ["edit_stock_adjustment"] },
    ...authGuard,
  },
  {
    path: "/lead/profile/:id",
    name: "lead-profile",
    component: () => import("../resources/EbillingProduct/Lead/LeadProfile"),
    meta: { title: "Lead Pofile", key: "Backend", permissions: ["view_lead_profile"] },
    ...authGuard,
  },
  {
    path: "/estimate",
    name: 'list-estimate',
    component: () => import("../resources/EbillingProduct/Estimate/Index"),
    meta: { title: "Manage Estimate", key: "Backend", permissions: ["view_estimate", "create_estimate"] },
    ...authGuard,
  },
  {
    path: "/estimate/edit/:id",
    name: 'edit-estimate',
    component: () => import("../resources/EbillingProduct/Estimate/Edit"),
    meta: { title: "Edit Estimate", key: "Backend", permissions: ["edit_estimate"] },
    ...authGuard,
  },
  {
    path: "/new/estimate",
    name: "new-estimate",
    component: () => import("../resources/EbillingProduct/Estimate/Create"),
    meta: { title: "New Estimate", key: "Backend", permissions: ["create_estimate"] },
    ...authGuard,
  },
  {
    path: "/account/expense",
    name: 'manage-expense',
    component: () =>
      import("../resources/EbillingProduct/Account/Expense/index"),
    meta: { title: "Manage Expense", key: "Backend", permissions: ["view_expense", 'create_expense'] },
    ...authGuard,
  },
  {
    path: "/account/income",
    name: 'manage-income',
    component: () =>
      import("../resources/EbillingProduct/Account/Income/index"),
    meta: { title: "Manage Income", key: "Backend", permissions: ["view_income", "create_income"] },
    ...authGuard,
  },
  {
    path: "/purchase/manage/:id",
    name: 'edit-purchase-by-id',
    component: () =>
      import("../resources/EbillingProduct/Purchase/EditPurchase"),
    meta: { title: "Edit Purchase", key: "Backend", permissions: ["edit_purchase"] },
    ...authGuard,
  },
  {
    path: "/purchase/manage",
    name: "purchase-listing",
    component: () =>
      import("../resources/EbillingProduct/Purchase/ManagePurchase"),
    meta: { title: "Manage Purchase", key: "Backend", permissions: ["view_purchase"] },
    ...authGuard,
  },
  {
    path: "/purchase/order",
    name: "purchase-order-listing",
    component: () =>
      import("../resources/EbillingProduct/Purchase/PurchaseOrder"),
    meta: { title: "Purchase Order", key: "Backend", permissions: ["view_purchase_order"] },
    ...authGuard,
  },
  {
    path: "/purchase/order/edit/:id",
    component: () => import("../resources/EbillingProduct/Purchase/editOrder"),
    meta: { title: "Edit Purchase Order", key: "Backend", permissions: ["edit_purchase_order"] },
    ...authGuard,
  },
  {
    path: "/purchase/return",
    name: 'list-purchase-returns',
    component: () =>
      import("../resources/EbillingProduct/Purchase/PurchaseReturn"),
    meta: { title: "List Purchase Returns", key: "Backend", permissions: ["view_purchase_return"] },
    ...authGuard,
  },
  {
    path: "/purchase/return/:id",
    name: 'specific-purchase-return',
    component: () =>
      import("../resources/EbillingProduct/Purchase/NewPurchaseReturn"),
    meta: { title: "New Purchase Return", key: "Backend", permissions: ["create_purchase_return"] },
    ...authGuard,
  },
  {
    path: "/supplier",
    component: () =>
      import("../resources/EbillingProduct/Supplier/ManageSupplier"),
    meta: { title: "Supplier", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account/capital",
    name: "capital-list",
    component: () =>
      import("../resources/EbillingProduct/Account/Capital/index"),
    meta: { title: "Manage Capital", key: "Backend", permissions: ['view_capital', 'add_capital', 'withdraw_capital'] },
    ...authGuard,
  },
  {
    path: "/sales/new",
    name: "new-sales",
    component: () => import("../resources/EbillingProduct/Sales/NewSale"),
    meta: { title: "New Sale", key: "Backend", permissions: ["create_sale"] },
    ...authGuard,
  },
  {
    path: "/sales/edit/:id",
    name: "sales-edit",
    component: () => import("../resources/EbillingProduct/Sales/EditSale"),
    meta: { title: "Edit Sale", key: "Backend", permissions: ["view_sale"] },
    ...authGuard,
  },
  {
    path: "/sales/order/checkout/:id",
    name: "sales-order-checkout",
    component: () => import("../resources/EbillingProduct/Sales/SalesOrderCheckout"),
    meta: { title: "Checkout Sales Order", key: "Backend", permissions: ["checkout_sale_order"] },
    ...authGuard,
  },
  {
    path: "/sales/manage",
    name: "list-sales",
    component: () => import("../resources/EbillingProduct/Sales/NewManageSales"),
    meta: { title: "Manage Sales", key: "Backend", permissions: ["view_sale"] },
    ...authGuard,
  },
  {
    path: "/sales/order",
    name: "list-sales-order",
    component: () => import("../resources/EbillingProduct/Sales/SalesOrder"),
    meta: { title: "Manage Sales Order", key: "Backend", permissions: ["view_sale_order"] },
    ...authGuard,
  },
  {
    path: "/sales/order/new",
    name: "new-sales-order",
    component: () => import("../resources/EbillingProduct/Sales/NewOrder"),
    meta: { title: "New Sales Order", key: "Backend", permissions: ["create_sale_order"] },
    ...authGuard,
  },
  {
    path: "/sales/order/manage/:id",
    name: "edit-sales-order",
    component: () => import("../resources/EbillingProduct/Sales/SalesOrderEdit"),
    meta: { title: "Edit Sales Order", key: "Backend", permissions: ["edit_sale_order"] },
    ...authGuard,
  },
  {
    path: "/account/investment",
    component: () =>
      import("../resources/EbillingProduct/Account/Investment/index"),
    meta: { title: "Manage Investment", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account/loan",
    name: "loan-list",
    component: () => import("../resources/EbillingProduct/Account/Loan/index"),
    meta: { title: "Manage Loan", key: "Backend", permissions: ['view_loan', 'add_loan', 'withdraw_loan'] },
    ...authGuard,
  },
  {
    path: "/inventory/stock-detail",
    name: "stock-detail",
    component: () =>
      import("../resources/EbillingProduct/Inventory/Stock/index"),
    meta: { title: "Stock Detail", key: "Backend", permissions: ["view_stock_detail"] },
    ...authGuard,
  },
  {
    path: "/lead",
    name: "manage-lead",
    component: () => import("../resources/EbillingProduct/Lead/ManageLead"),
    meta: { title: "Lead", key: "Backend", permissions: ['view_lead', 'create_lead'] },
    ...authGuard,
  },
  {
    path: "/lead/profile",
    name: "view-lead-profile",
    component: () => import("../resources/EbillingProduct/Lead/LeadProfile"),
    meta: { title: "Lead Profile", key: "Backend", permissions: ['view_lead_profile'] },
    ...authGuard,
  },
  {
    path: "/lead/estimate",
    component: () => import("../resources/EbillingProduct/Lead/ManageEstimate"),
    meta: { title: "Lead Estimate", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/lead/newestimate",
    component: () => import("../resources/EbillingProduct/Lead/NewEstimate"),
    meta: { title: "Lead Estimate", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/investment",
    component: () =>
      import("../resources/EbillingProduct/Account/Investment/index"),
    meta: { title: "Manage Investment", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/customer/profile/:id",
    nmame: "customer-profile",
    component: () =>
      import("../resources/EbillingProduct/Customer/CustomerProfile"),
    meta: { title: "Manage Customer", key: "Backend", permissions: ['view_customer_profile'] },
    ...authGuard,
  },
  {
    path: "/supplier/profile/:id",
    component: () =>
      import("../resources/EbillingProduct/Supplier/SupplierProfile"),
    meta: { title: "Supplier Profile", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account/pay-in",
    name: "payin-list",
    component: () =>
      import("../resources/EbillingProduct/Account/Pay-In/index"),
    meta: { title: "Pay-In", key: "Backend", permissions: ['view_pay_in', "create_pay_in"] },
    ...authGuard,
  },
  {
    path: "/account/pay-out",
    name: "payout-list",
    component: () =>
      import("../resources/EbillingProduct/Account/Pay-Out/index"),
    meta: { title: "Pay-Out", key: "Backend", permissions: ['view_pay_out', 'create_pay_out'] },
    ...authGuard,
  },
  {
    path: "/system-setting",
    component: () => import("../resources/EbillingProduct/Setting/index"),
    meta: { title: "Setting", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/human-resources/employee",
    name: "employee-list",
    component: () =>
      import("../resources/EbillingProduct/HumanResource/Employee/index"),
    meta: { title: "Manage Employee", key: "Backend", permissions: ['view_employee', 'create_employee'] },
    ...authGuard,
  },
  {
    path: "/human-resources/employee/profile/:id",
    name: "employee-profile",
    component: () =>
      import("../resources/EbillingProduct/HumanResource/Employee/profile"),
    meta: { title: "Employee Profile", key: "Backend", permissions: ['view_employee_profile'] },
    ...authGuard,
  },
  {
    path: "/human-resources/salary-payroll",
    name: "salary-payroll-list",
    component: () =>
      import("../resources/EbillingProduct/HumanResource/SalaryPayroll/index"),
    meta: { title: "Manage Salary Payroll", key: "Backend", permissions: ['view_salary_payroll', 'create_salary_payroll'] },
    ...authGuard,
  },
  {
    path: "/human-resources/salary-master",
    name: "salary-master-view",
    component: () =>
      import("../resources/EbillingProduct/HumanResource/SalaryMaster/index"),
    meta: { title: "Salary master", key: "Backend", permissions: ['view_salary_master'] },
    ...authGuard,
  },
  {
    path: "/human-resources/attendance-day",
    name: 'attendence-day-view',
    component: () =>
      import("../resources/EbillingProduct/HumanResource/Attendance/indexDay"),
    meta: { title: "Day-Attendace Report ", key: "Backend", permissions: ['view_attendance', 'create_attendance'] },
    ...authGuard,
  },
  {
    path: "/human-resources/attendance-month",
    name: 'attendence-month-view',
    component: () =>
      import(
        "../resources/EbillingProduct/HumanResource/Attendance/indexMonth"
      ),
    meta: { title: "Month-Attendance Report", key: "Backend", permissions: ['view_attendance', 'create_attendance'] },
    ...authGuard,
  },
  {
    path: "/human-resources/attendance-create",
    name: "attendence-create",
    component: () =>
      import("../resources/EbillingProduct/HumanResource/Attendance/create"),
    meta: { title: "Create Attendance", key: "Backend", permissions: ['create_attendance'] },
    ...authGuard,
  },
  {
    path: "/human-resources/individual-attendance/:month/:id",
    name: 'individual-employee-attendence',
    component: () =>
      import(
        "../resources/EbillingProduct/HumanResource/Attendance/individualReport"
      ),
    meta: { title: "Update Attendance", key: "Backend", permissions: ['view_attendance'] },
    ...authGuard,
  },
  {
    path: "/account/manage-account",
    name: "manage-account",
    component: () =>
      import("../resources/EbillingProduct/Account/ManageAccount/index"),
    meta: { title: "Manage Account", key: "Backend", permissions: ['view_manage_account', 'create_manage_account'] },
    ...authGuard,
  },
  {
    path: "/account/payment-method",
    name: "payment-method-list",
    component: () =>
      import("../resources/EbillingProduct/Account/PaymentMethods/index"),
    meta: { title: "Payment Method", key: "Backend", permissions: ['view_payment_method', 'create_payment_method'] },
    ...authGuard,
  },
  {
    path: "/account/tax/payroll",
    name: "manage-tax-payroll",
    component: () =>
      import("../resources/EbillingProduct/Account/TaxPayroll/index"),
    meta: { title: "Tax Payroll", key: "Backend", permissions: ['view_tax_payroll', 'create_tax_payroll'] },
    ...authGuard,

  },
  {
    path: "/account/balance/adjustment",
    name: "manage-balance-adjustment",
    component: () =>
      import("../resources/EbillingProduct/Account/BalanceAdjustment/index"),
    meta: { title: "Balance Adjustment", key: "Backend", permissions: ['view_balance_adjustment', 'create_balance_adjustment'] },
    ...authGuard,

  },
  {
    path: "/account/tax/payable",
    name: "manage-tax-payable",
    component: () =>
      import("../resources/EbillingProduct/Account/TaxPayable/index"),
    meta: { title: "Tax Payable", key: "Backend", permissions: ['view_tax_payable', 'create_tax_payable'] },
    ...authGuard,

  },
  {
    path: "/account/bank",
    name: "manage-banks",
    component: () => import("../resources/EbillingProduct/Account/Bank/index"),
    meta: { title: "Manage Bank", key: "Backend", permissions: ['view_bank', 'create_bank'] },
    ...authGuard,
  },
  {
    path: "/account/assets",
    name: "manage-assets",
    component: () =>
      import("../resources/EbillingProduct/Account/Assets/index"),
    meta: { title: "Manage Assets", key: "Backend", permissions: ['view_asset', 'new_asset', 'sell_asset'] },
    ...authGuard,
  },
  {
    path: "/account/amount-transfer",
    name: "manage-amount-transfer",
    component: () =>
      import("../resources/EbillingProduct/Account/AmountTransfer/index"),
    meta: { title: "Manage Amount Transfer", key: "Backend", permissions: ['view_amount_transfer', 'create_amount_transfer'] },
    ...authGuard,
  },
  {
    path: "/contact-detail",
    name: "contact-detail-list",
    component: () => import("../resources/EbillingProduct/ContactDetail/index"),
    meta: { title: "Manage Contact Details", key: "Backend", permissions: ['view_contact_detail', 'create_contact_detail'] },
    ...authGuard,
  },
  {
    path: "/general-report/sales",
    name: "sales-report-biil-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalesReport/BillsWise"
      ),
    meta: { title: "Sales Report", key: "Backend", permissions: ['view_sales_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/sales/branch-wise",
    name: "sales-report-branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalesReport/BranchWise"
      ),
    meta: { title: "Sales Report", key: "Backend", permissions: ['view_sales_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/sales/day-wise",
    name: "sales-report-day-wise",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/SalesReport/DayWise"),
    meta: { title: "Sales Report", key: "Backend", permissions: ['view_sales_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/sales/month-wise",
    name: "sales-report-month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalesReport/MonthWise"
      ),
    meta: { title: "Sales Report", key: "Backend", permissions: ['view_sales_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/sales/customer-wise",
    name: "sales-report-customer-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalesReport/CustomerWise"
      ),
    meta: { title: "Sales Report", key: "Backend", permissions: ['view_sales_report'] },
    ...authGuard,
  },

  {
    path: "/general-report/purchase",
    name: "purchase-report-bill-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/BillsWise"
      ),
    meta: { title: "Purchase Report", key: "Backend", permissions: ['view_purchase_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/purchase/branch-wise",
    name: "purchase-report-branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/BranchWise"
      ),
    meta: { title: "Purchase Report", key: "Backend", permissions: ['view_purchase_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/purchase/day-wise",
    name: "purchase-report-day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/DayWise"
      ),
    meta: { title: "Purchase Report", key: "Backend", permissions: ['view_purchase_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/purchase/month-wise",
    name: "purchase-report-month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/MonthWise"
      ),
    meta: { title: "Purchase Report", key: "Backend", permissions: ['view_purchase_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/purchase/supplier-wise",
    name: "purchase-report-supplier-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/SupplierWise"
      ),
    meta: { title: "Purchase Report", key: "Backend", permissions: ['view_purchase_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/expense",
    name: "expense-report-account-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/ExpenseReport/AccountWise"
      ),
    meta: { title: "Expense Report", key: "Backend", permissions: ['view_expense_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/expense/branch-wise",
    name: "expense-report-branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/ExpenseReport/BranchWise"
      ),
    meta: { title: "Expense Report", key: "Backend", permissions: ['view_expense_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/expense/day-wise",
    name: "expense-report-day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/ExpenseReport/DayWise"
      ),
    meta: { title: "Expense Report", key: "Backend", permissions: ['view_expense_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/expense/month-wise",
    name: "expense-report-month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/ExpenseReport/MonthWise"
      ),
    meta: { title: "Expense Report", key: "Backend", permissions: ['view_expense_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/income",
    name: "income-report-account-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/IncomeReport/AccountWise"
      ),
    meta: { title: "Income Report", key: "Backend", permissions: ['view_income_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/income/branch-wise",
    name: "income-report-branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/IncomeReport/BranchWise"
      ),
    meta: { title: "Income Report", key: "Backend", permissions: ['view_income_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/income/day-wise",
    name: "income-report-day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/IncomeReport/DayWise"
      ),
    meta: { title: "Income Report", key: "Backend", permissions: ['view_income_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/income/month-wise",
    name: "income-report-month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/IncomeReport/MonthWise"
      ),
    meta: { title: "Income Report", key: "Backend", permissions: ['view_income_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/assets",
    name: "assets-report-account-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/AssetsReport/AccountWise"
      ),
    meta: { title: "Asset Report", key: "Backend", permissions: ['view_income_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/assets/branch-wise",
    name: "assets-report-branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/AssetsReport/BranchWise"
      ),
    meta: { title: "Asset Report", key: "Backend", permissions: ['view_income_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/assets/day-wise",
    name: "assets-report-day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/AssetsReport/DayWise"
      ),
    meta: { title: "Asset Report", key: "Backend", permissions: ['view_income_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/assets/month-wise",
    name: "assets-report-month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/AssetsReport/MonthWise"
      ),
    meta: { title: "Asset Report", key: "Backend", permissions: ['view_income_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/capital",
    name: "capital-report-account-wise",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/CapitalReport/AccountWise"),
    meta: { title: "Capital Report", key: "Backend", permissions: ['view_capital_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/capital/transaction-wise",
    name: "capital-report-transaction-wise",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/CapitalReport/TransactionWise"),
    meta: { title: "Capital Report", key: "Backend", permissions: ['view_capital_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/investment",
    name: "investment-report",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/InvestmentReport/AccountWise"),
    meta: { title: "Investment Report", key: "Backend", permissions: ['view_investment_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/investment/transaction-wise",
    name: "investment-report-transaction-wise",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/InvestmentReport/TransactionWise"),
    meta: { title: "Investment Report", key: "Backend", permissions: ['view_investment_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/loan",
    name: "loan-report",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/LoanReport/AccountWise"),
    meta: { title: "Loan Report", key: "Backend", permissions: ['view_loan_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/loan/transaction-wise",
    name: "loan-report-transaction-wise",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/LoanReport/TransactionWise"),
    meta: { title: "Loan Report", key: "Backend", permissions: ['view_loan_report'] },
    ...authGuard,
  },
  {
    path: "/general-report/stock",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/ItemWithQuantity"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/item-value",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/ItemWithValue"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/item-state",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/ItemWithState"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/day-quantity",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/DayWithQuantity"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/day-value",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/DayWithValue"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/month-quantity",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/MonthWithQuantity"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/month-value",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/MonthWithValue"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/branch-quantity",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/BranchWithQuantity"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/branch-value",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/BranchWithValue"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/expiry",
    name: "expiry-report",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/ExpiryReport/ExpiryReport"),
    meta: { title: "Expiry Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/bills",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/SalesBills"
      ),
    meta: { title: " Sales Bills and Receipts", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/purchasesBills",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/PurchasesBills"
      ),
    meta: { title: " Purchases Bills and Receipts", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payInReceipts",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/PayInReceipts"
      ),
    meta: { title: " Pay In Receipts", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payOutReceipts",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/PayOutReceipts"
      ),
    meta: { title: " Pay Out Receipts", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/salarySlips",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/SalarySlips"
      ),
    meta: { title: "Salary Slip", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/TransactionWise"
      ),
    meta: { title: "Payment Reports", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report/branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/BranchWise"
      ),
    meta: { title: "Payment Reports", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report/day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/DayWise"
      ),
    meta: { title: "Payment Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report/Month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/MonthWise"
      ),
    meta: { title: "Payment Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report/method-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/MethodWise"
      ),
    meta: { title: "Payment Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/salary-report",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalaryReport/EmployeeWise"
      ),
    meta: { title: "Salary Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/salary-report/month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalaryReport/MonthWise"
      ),
    meta: { title: "Salary Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/salary-report/transaction-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalaryReport/TransactionWise"
      ),
    meta: { title: "Salary Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/salary-report/payroll-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalaryReport/PayrollWise"
      ),
    meta: { title: "Salary Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account-report/ledger",
    component: () =>
      import("../resources/EbillingProduct/ReportAccount/Ledger"),
    meta: { title: "Ledger", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account-report/trial-balance",
    name: "trial-balance-report",
    component: () =>
      import("../resources/EbillingProduct/ReportAccount/TrialReport/TrialBalanceReport"),
    meta: { title: "Trial Balance", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account-report/balance-sheet",
    name: "balance-sheet-journal",
    component: () =>
      import("../resources/EbillingProduct/ReportAccount/BalanceSheet/BalanceSheet"),
    meta: { title: "Balance Sheet", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account-report/daybook",
    name: "daybook",
    component: () =>
      import("../resources/EbillingProduct/ReportAccount/Daybook/Daybook"),
    meta: { title: "Daybook", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/tools/brand",
    component: () => import("../resources/EbillingProduct/Tools/Brand/index"),
    meta: { title: "Brand", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/academic-year-transfer",
    component: () =>
      import(
        "../resources/EbillingProduct/Setting/SettingModules/AcademicYearSrtting/Componenets/Customer"
      ),
    meta: { title: "Academic Year Transfer", key: "YearTransfer" },
    ...authGuard,
  },
  {
    path: "/academic-year-transfer/supplier",
    component: () =>
      import(
        "../resources/EbillingProduct/Setting/SettingModules/AcademicYearSrtting/Componenets/Supplier"
      ),
    meta: { title: "Academic Year Transfer", key: "YearTransfer" },
    ...authGuard,
  },
  {
    path: "/academic-year-transfer/item",
    component: () =>
      import(
        "../resources/EbillingProduct/Setting/SettingModules/AcademicYearSrtting/Componenets/Item"
      ),
    meta: { title: "Academic Year Transfer", key: "YearTransfer" },
    ...authGuard,
  },
  {
    path: "/academic-year-transfer/employee",
    component: () =>
      import(
        "../resources/EbillingProduct/Setting/SettingModules/AcademicYearSrtting/Componenets/Employee"
      ),
    meta: { title: "Academic Year Transfer", key: "YearTransfer" },
    ...authGuard,
  },
  {
    path: "/academic-year-transfer/payment-method",
    component: () =>
      import(
        "../resources/EbillingProduct/Setting/SettingModules/AcademicYearSrtting/Componenets/PaymentMethods"
      ),
    meta: { title: "Academic Year Transfer", key: "YearTransfer" },
    ...authGuard,
  },
  {
    path: "/academic-year-transfer/bank",
    component: () =>
      import(
        "../resources/EbillingProduct/Setting/SettingModules/AcademicYearSrtting/Componenets/Bank"
      ),
    meta: { title: "Academic Year Transfer", key: "YearTransfer" },
    ...authGuard,
  },
  {
    path: "/general-report/purchase",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/BillsWise"
      ),
    meta: { title: "Purchase Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/purchase/branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/BranchWise"
      ),
    meta: { title: "Purchase Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/purchase/day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/DayWise"
      ),
    meta: { title: "Purchase Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/purchase/month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/MonthWise"
      ),
    meta: { title: "Purchase Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/purchase/supplier-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PurchaseReport/SupplierWise"
      ),
    meta: { title: "Purchase Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/expense",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/ExpenseReport/AccountWise"
      ),
    meta: { title: "Expense Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/expense/branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/ExpenseReport/BranchWise"
      ),
    meta: { title: "Expense Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/expense/day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/ExpenseReport/DayWise"
      ),
    meta: { title: "Expense Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/expense/month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/ExpenseReport/MonthWise"
      ),
    meta: { title: "Expense Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/income",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/IncomeReport/AccountWise"
      ),
    meta: { title: "Income Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/income/branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/IncomeReport/BranchWise"
      ),
    meta: { title: "Income Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/income/day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/IncomeReport/DayWise"
      ),
    meta: { title: "Income Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/income/month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/IncomeReport/MonthWise"
      ),
    meta: { title: "Income Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/assets",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/AssetsReport/AccountWise"
      ),
    meta: { title: "Assets Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/assets/branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/AssetsReport/BranchWise"
      ),
    meta: { title: "Assets Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/assets/day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/AssetsReport/DayWise"
      ),
    meta: { title: "Assets Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/assets/month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/AssetsReport/MonthWise"
      ),
    meta: { title: "Assets Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/capital",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/CapitalReport"),
    meta: { title: "Capital Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/investment",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/InvestmentReport"),
    meta: { title: "Investment Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/loan",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/LoanReport"),
    meta: { title: "Loan Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/ItemWithQuantity"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/item-value",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/ItemWithValue"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/item-state",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/ItemWithState"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/day-quantity",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/DayWithQuantity"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/day-value",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/DayWithValue"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/month-quantity",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/MonthWithQuantity"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/month-value",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/MonthWithValue"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/branch-quantity",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/BranchWithQuantity"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/stock/branch-value",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/StockReport/BranchWithValue"
      ),
    meta: { title: "Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/min-stock",
    component: () =>
      import("../resources/EbillingProduct/ReportsGeneral/MinStockReport/MinStockReport"),
    meta: { title: "Min Stock Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/bills",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/SalesBills"
      ),
    meta: { title: " Sales Bills and Receipts", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/purchasesBills",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/PurchasesBills"
      ),
    meta: { title: " Purchases Bills and Receipts", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payInReceipts",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/PayInReceipts"
      ),
    meta: { title: " Pay In Receipts", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payOutReceipts",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/PayOutReceipts"
      ),
    meta: { title: " Pay Out Receipts", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/salarySlips",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/BillsReceiptsReport/SalarySlips"
      ),
    meta: { title: "Salary Slip", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/TransactionWise"
      ),
    meta: { title: "Payment Reports", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report/branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/BranchWise"
      ),
    meta: { title: "Payment Reports", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report/day-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/DayWise"
      ),
    meta: { title: "Payment Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report/Month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/MonthWise"
      ),
    meta: { title: "Payment Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/payment-report/method-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/PaymentReport/MethodWise"
      ),
    meta: { title: "Payment Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/salary-report",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalaryReport/EmployeeWise"
      ),
    meta: { title: "Payment Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/salary-report/month-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalaryReport/MonthWise"
      ),
    meta: { title: "Payment Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/general-report/salary-report/branch-wise",
    component: () =>
      import(
        "../resources/EbillingProduct/ReportsGeneral/SalaryReport/BranchWise"
      ),
    meta: { title: "Salary Report", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account-report/journal",
    name: "journal-report",
    component: () =>
      import("../resources/EbillingProduct/ReportAccount/JournalReport/Journal"),
    meta: { title: "Journal", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account-report/ledger",
    component: () =>
      import("../resources/EbillingProduct/ReportAccount/Ledger"),
    meta: { title: "Leder", key: "Backend" },
    ...authGuard,
  },

  {
    path: "/account-report/pl-account",
    component: () =>
      import("../resources/EbillingProduct/ReportAccount/ProfitAndLossReport/ProfitAndLossReport"),
    meta: { title: "Profit and Loss Account", key: "Backend" },
    ...authGuard,
  },
  // {
  //   path: "/account-report/profit-loss-account-report",
  //   name:"profit-loss-account-report",
  //   component: () =>
  //     import("../resources/EbillingProduct/ReportAccount/ProfitAndLossReport/ProfitAndLossReport"),
  //   meta: { title: "Profit and Loss Account", key: "Backend" },
  //   ...authGuard,
  // },

  // {
  //   path: "/account-report/vat-report",
  //   component: () =>
  //     import("../resources/EbillingProduct/ReportAccount/VatReport/MonthWise"),
  //   meta: { title: "Balance Sheet", key: "Backend" },
  //   ...authGuard,
  // },
  // {
  //   path: "/account-report/vat-report/branch-wise",
  //   component: () =>
  //     import("../resources/EbillingProduct/ReportAccount/VatReport/BranchWise"),
  //   meta: { title: "Balance Sheet", key: "Backend" },
  //   ...authGuard,
  // },
  {
    path: "/account-report/vat-report/bikrikhata",
    component: () =>
      import("../resources/EbillingProduct/ReportAccount/VatReport/BikriKhata"),
    meta: { title: "Vat Report - Bikri Khata", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/account-report/vat-report/kharidkhata",
    component: () =>
      import("../resources/EbillingProduct/ReportAccount/VatReport/KharidKhata"),
    meta: { title: "Vat Report - Kharid Khata", key: "Backend" },
    ...authGuard,
  },
  // {
  //   path: "/account-report/vat-report/day-wise",
  //   component: () =>
  //     import("../resources/EbillingProduct/ReportAccount/VatReport/DayWise"),
  //   meta: { title: "Balance Sheet", key: "Backend" },
  //   ...authGuard,
  // },
  {
    path: "/tools/barcode",
    name: "barcode-generator",
    component: () => import("../resources/EbillingProduct/Tools/Barcode/Barcode"),
    meta: { title: "Barcode", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/barcode/view",
    name: "view-barcode",
    component: () => import("../resources/EbillingProduct/Tools/Barcode/ViewBarcode"),
    meta: { title: "Barcode View", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/tools/table",
    component: () => import("../resources/EbillingProduct/ResturantModule/Table/index"),
    meta: { title: "Tables", key: "Backend" },
    ...authGuard,
  },
  {
    path: "/user-guide",
    component: () => import("../resources/EbillingUserGuide/index"),
    meta: { title: "User Guide - Ebilling", key: "YearTransfer" },
  },
  {
    path: "/cheque-print",
    component: () => import("../resources/EbillingProduct/ChequePrint/index"),
    meta: { title: "Cheque Print", key: "Backend" },
    ...authGuard,
  },
  // {
  //   path: "/404-error",
  //   component: () => import("../shared/404Error"),
  //   meta: { title: "404 Error", key: "YearTransfer" },
  //   ...authGuard,
  // }

];

export default new VueRouter({
  mode: "history",
  routes: index,
  scrollBehavior(from, to, savedPosition) {
    return {
      x: 0,
      y: 0,
    };
  },
});
