const module ={
    namespaced : true,
    state:{
        assetsLists:{},
        assetsElements:{},
    },
    getters:{
       assetsLists(state){
           return state.assetsLists;
       },
       assetsElements(state){
           return state.assetsElements;
       }
    },
    mutations:{
        setAssetsLists(state,payload){
            state.assetsLists=payload;
        },
        setAssetsElements(state,payload){
            state.assetsElements = payload;
        }
    }
}
export default module;