<template>
  <span>
    <router-link
      to="/free-trial"
      :style="{ cursor: 'pointer' }"
      class="btn btn-primary text-uppercase border-radius-0"
      tag="a"
      v-if="token"
    >try for free</router-link>
    <button
      type="button"
      @click="setComponent"
      class="btn btn-primary text-uppercase border-radius-0"
      data-toggle="modal"
      data-target="#loginRegisterModal"
      v-else
    >try for free</button>
  </span>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    setComponent() {
      this.$store.commit("setCurrentComponent", "free-trial");
    },
  },
  computed: {
    ...mapGetters(["token"]),
  },
};
</script>