const module ={
    namespaced : true,
    state:{
       salaryDataAtrributes:{},
       employeeWiseDatas:{},
       transactionWiseDatas:{}, 
       monthWiseDatas:{},
    },
    getters:{
        salaryDataAtrributes(state){
            return state.salaryDataAtrributes;
        },
        employeeWiseDatas(state){
            return state.employeeWiseDatas;
        },
        transactionWiseDatas(state){
            return state.transactionWiseDatas;
        },
        monthWiseDatas(state){
            return state.monthWiseDatas;
        }
    },
    mutations:{
        setSalaryDataAttributes(state,payload){
            state.salaryDataAtrributes = payload;
        },
        setEmployeeWiseDatas(state,payload){
            state.employeeWiseDatas = payload;
        },
        setTransactionWiseDatas(state,payload){
            state.transactionWiseDatas = payload;
        },
        setMonthWiseDatas(state,payload){
            state.monthWiseDatas = payload;
        }
    }
}
export default module;