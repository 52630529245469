const module ={
    namespaced : true,
    state:{
       expenseDataAttributes:{},
       accountWiseDatas:{},
       branchWiseDatas:{},
       dayWiseDatas:{},
       monthWiseDatas:{}, 
    },
    getters:{
        expenseDataAttributes(state){
            return state.expenseDataAttributes;
        },
        accountWiseDatas(state){
            return state.accountWiseDatas;
        },
        branchWiseDatas(state){
            return state.branchWiseDatas;
        },
        dayWiseDatas(state){
            return state.dayWiseDatas;
        },
        monthWiseDatas(state){
            return state.monthWiseDatas;
        }
    },
    mutations:{
        setExpenseDataAttributes(state,payload){
            state.expenseDataAttributes = payload;
        },
        setAccountWiseDatas(state,payload){
            state.accountWiseDatas = payload;
        },
        setBranchWiseDatas(state,payload){
            state.branchWiseDatas = payload;
        },
        setDayWiseDatas(state,payload){
            state.dayWiseDatas = payload;
        },
        setMonthWiseDatas(state,payload){
            state.monthWiseDatas = payload;
        }
    }
}
export default module;